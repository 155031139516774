import { createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import { LoadingStatus, status } from "../../app/shared";
import {
  addWirelessInterfaceToDevice,
  approveDevice,
  assignDevice,
  checkDevicePermissions,
  copyFromTemplate,
  createNewDevice,
  deleteDevice,
  deleteDevicePath,
  deleteDevicePoint,
  deleteWirelessInterface,
  doSearch,
  rejectDevice,
  requestAllDevicesByQuery,
  requestDeviceById,
  requestMyRequests,
  saveDevicePath,
  submitDevice,
  updateDevice, updateDeviceOperators, updateDeviceOwners,
  updateWirelessInterface,
  upsertDevicePoint,
} from "./deviceRequests";
import DeviceModel from "../../models/DeviceModel";

export const deviceAdapter = createEntityAdapter<DeviceModel>({
  selectId: (device) => device.id,
  sortComparer: (a, b) => (a.name || '').localeCompare(b.name || ''),
});

type DeviceState = {
  selectedDeviceId: number | null,
  currentDevicePermissions: {
    currentDeviceId: number | null,
    canEdit: boolean,
    canDelete: boolean,
  },
  // todo: extract if needed
  selectedWirelessInterfaceId: number | null,
  searchDeviceIdList: number[],
  myRequestsDeviceIdList: number[],
  myWorkflowDeviceIdList: number[],
  loadAllStatus: LoadingStatus,
  // todo: divide into separate loadings
  otherLoadings: LoadingStatus,
} & EntityState<DeviceModel>

const initialState: DeviceState = {
  selectedDeviceId: null,
  currentDevicePermissions: {
    currentDeviceId: null,
    canEdit: false,
    canDelete: false,
  },
  selectedWirelessInterfaceId: null,
  searchDeviceIdList: [],
  myRequestsDeviceIdList: [],
  myWorkflowDeviceIdList: [],
  loadAllStatus: status.default,
  otherLoadings: status.default,
  ...deviceAdapter.getInitialState(),
};

export const deviceSlice = createSlice({
  name: 'deviceList',
  initialState,
  reducers: {
    setCurrentDeviceId: (state, action) => {
      state.selectedDeviceId = action.payload;
      state.selectedWirelessInterfaceId = null;
    },
    setCurrentWirelessInterfaceId: (state, action) => {
      state.selectedWirelessInterfaceId = action.payload;
    },
    clearDeviceList: (state) => {
      deviceAdapter.removeAll(state);
    },
    clearIdList: (state) => {
      state.myRequestsDeviceIdList = [];
      state.myWorkflowDeviceIdList = [];
      state.searchDeviceIdList = [];
    },
    resetDevicePermissions: (state, action) => {
      state.currentDevicePermissions = {
        currentDeviceId: action.payload,
        canEdit: false,
        canDelete: false,
      }
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(requestAllDevicesByQuery.pending, (state) => {
        state.loadAllStatus = status.loading;
      })
      .addCase(requestAllDevicesByQuery.rejected, (state, action) => {
        state.loadAllStatus = status.error(action.error);
      })
      .addCase(requestAllDevicesByQuery.fulfilled, (state, action) => {
        deviceAdapter.upsertMany(state, action.payload);
        state.loadAllStatus = status.loaded;
      })
      .addCase(requestDeviceById.pending, (state) => {
        state.otherLoadings = status.loading;
      })
      .addCase(requestDeviceById.rejected, (state, action) => {
        state.otherLoadings = status.error(action.error);
      })
      .addCase(requestDeviceById.fulfilled, (state, action) => {
        deviceAdapter.upsertOne(state, action.payload);
        state.otherLoadings = status.loaded;
      })
      .addCase(createNewDevice.fulfilled, (state, action) => {
        deviceAdapter.addOne(state, action.payload);
      })
      .addCase(deleteDevice.fulfilled, (state, action) => {
        const { device, deviceId } = action.payload;
        if (device) {
          deviceAdapter.upsertOne(state, device);
        } else {
          if (deviceId) {
            deviceAdapter.removeOne(state, deviceId);
          }
        }
      })
      .addCase(doSearch.pending, (state) => {
        state.loadAllStatus = status.loading;
      })
      .addCase(doSearch.rejected, (state, action) => {
        state.loadAllStatus = status.error(action.error);
      })
      .addCase(doSearch.fulfilled, (state, action) => {
        state.loadAllStatus = status.loaded;
        state.searchDeviceIdList = (action.payload || []).map((device) => device.id);
        deviceAdapter.upsertMany(state, action.payload);
      })
      .addCase(requestMyRequests.fulfilled, (state, action) => {
        const { myDevicesList, myWorkflowList } = action.payload || [];
        const deviceList = [ ...myDevicesList, ...myWorkflowList ];
        state.myRequestsDeviceIdList = myDevicesList.map((device) => device.id);
        state.myWorkflowDeviceIdList = myWorkflowList.map((device) => device.id);

        deviceAdapter.setMany(state, deviceList);
      })
      .addCase(upsertDevicePoint.fulfilled, (state, action) => {
        if (action.payload) {
          deviceAdapter.upsertOne(state, action.payload);
        }
      })
      .addCase(saveDevicePath.fulfilled, (state, action) => {
        if (action.payload) {
          deviceAdapter.upsertOne(state, action.payload);
        }
      })
      .addCase(deleteDevicePoint.fulfilled, (state, action) => {
        if (action.payload) {
          deviceAdapter.upsertOne(state, action.payload);
        }
      })
      .addCase(deleteDevicePath.fulfilled, (state, action) => {
        if (action.payload) {
          deviceAdapter.upsertOne(state, action.payload);
        }
      })

      .addCase(copyFromTemplate.fulfilled, (state, action) => {
        deviceAdapter.upsertOne(state, action.payload);
        // clear prev wirelessInterface
        state.selectedWirelessInterfaceId = null;
      })
      .addCase(checkDevicePermissions.fulfilled, (state, action) => {
        state.currentDevicePermissions = {
          ...state.currentDevicePermissions,
          ...action.payload,
        }
      })
      .addMatcher(isAnyOf(
          submitDevice.fulfilled, approveDevice.fulfilled, rejectDevice.fulfilled, updateWirelessInterface.fulfilled, deleteWirelessInterface.fulfilled,
        ),
        (state, action) => {
          if (action.payload?.device) {
            deviceAdapter.upsertOne(state, action.payload.device);
          }
        })
      .addMatcher(isAnyOf(
        addWirelessInterfaceToDevice.fulfilled, assignDevice.fulfilled, updateDevice.fulfilled, updateDeviceOwners.fulfilled, updateDeviceOperators.fulfilled,
      ), (state, action) => {
        deviceAdapter.upsertOne(state, action.payload);
      })
});

export const { setCurrentDeviceId, setCurrentWirelessInterfaceId, clearDeviceList, clearIdList, resetDevicePermissions } = deviceSlice.actions;