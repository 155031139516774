import React from "react";
import { GridColDef } from "@material-ui/data-grid";
import { Link, Typography } from "@material-ui/core";
import { capitalize } from "../../helpers/stringHelpers";
import { dateFormatted } from "../../helpers/timeHelpers";
import DeviceIcon from "../DeviceIcon/DeviceIcon";

const deviceNameClick = (e) => {
  e.stopPropagation();
}

function getDeviceIconColumn(): GridColDef {
  return {
    field: 'device',
    headerName: ' ',
    width: 25,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      const device = params.formattedValue;
      return <DeviceIcon deviceConfiguration={device?.configuration} />
    }
  }
}

function getDeviceNameColumn(): GridColDef {
  return {
    field: 'name',
    headerName: 'Name',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return params.value?.showDeviceLink ? (
        <Link href={params.value?.link} target="_blank" onClick={deviceNameClick}>
          <Typography variant='body1'>
            {params.value?.text}
          </Typography>
        </Link>
      ) : (
        <Typography variant='body1'>
          {params.value?.text}
        </Typography>
      )
    },
  }
}

function getDeviceOriginColumn(): GridColDef {
  return {
    field: 'origin',
    headerName: 'Origin',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {params.value}
        </Typography>
      );
    },
  }
}

function getWirelessSystemColumn(): GridColDef {
  return {
    field: 'wirelessSystem',
    headerName: 'Wireless System',
    editable: false,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    minWidth: 160,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {params.value}
        </Typography>
      );
    },
  }
}

function getDeviceRequester(): GridColDef {
  return {
    field: 'requester',
    headerName: 'Requester',
    editable: false,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    minWidth: 160,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {params.value || 'User unspecified'}
        </Typography>
      );
    },
  }
}

function getDeviceOwner(): GridColDef {
  return {
    field: 'owner',
    headerName: 'Owners',
    editable: false,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    minWidth: 160,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {params.value}
        </Typography>
      );
    },
  }
}

function getDeviceOperator(): GridColDef {
  return {
    field: 'operator',
    headerName: 'Operators',
    editable: false,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    minWidth: 160,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {params.value}
        </Typography>
      );
    },
  }
}

function getFrequencyManager(): GridColDef {
  return {
    field: 'manager',
    headerName: 'Frequency manager',
    editable: false,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    minWidth: 160,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {params.value}
        </Typography>
      );
    },
  }
}

function getWirelessInterface(): GridColDef {
  return {
    field: 'wirelessInterfaceList',
    headerName: 'Wireless interface',
    editable: false,
    sortable: false,
    minWidth: 240,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {capitalize(params.value || 'No wireless interfaces')}
        </Typography>
      );
    },
  }
}

function getLastUpdated(): GridColDef {
  return {
    field: 'updatedAt',
    headerName: 'Last update',
    editable: false,
    sortable: false,
    minWidth: 200,
    disableColumnMenu: true,
    renderCell: (params: any): any => {
      return (
        <Typography variant='body1'>
          {dateFormatted(params.value)}
        </Typography>
      );
    },
  }
}

export interface DeviceColumnList {
  showOrigin?: boolean,
  showWirelessSystem?: boolean,
  showRequester?: boolean,
  showDeviceOwner?: boolean,
  showDeviceOperator?: boolean,
  showDeviceManager?: boolean,
  showWirelessInterface?: boolean,
  showLastUpdated?: boolean,
}

function defaultTo(value, def): boolean {
  return typeof value === 'undefined' ? def : !!value;
}

function createDeviceColumns(params: DeviceColumnList): GridColDef[] {
  const result = [ getDeviceIconColumn(), getDeviceNameColumn() ];

  defaultTo(params.showOrigin, true) && result.push(getDeviceOriginColumn());
  defaultTo(params.showWirelessSystem, true) && result.push(getWirelessSystemColumn());
  defaultTo(params.showRequester, false) && result.push(getDeviceRequester());
  defaultTo(params.showDeviceOwner, true) && result.push(getDeviceOwner());
  defaultTo(params.showDeviceOperator, false) && result.push(getDeviceOperator());
  defaultTo(params.showDeviceManager, true) && result.push(getFrequencyManager());
  defaultTo(params.showWirelessInterface, true) && result.push(getWirelessInterface());
  defaultTo(params.showLastUpdated, true) && result.push(getLastUpdated());

  return result;
}

export default createDeviceColumns;