import React, { useEffect, useMemo, useState } from 'react';
import * as d3 from 'd3';
import { extractNumber, extractUnits } from 'helpers/converters/units';
import { convertToHz } from "../../../helpers/converters/frequency";

const FrequencyAnalysisCanvas = (props) => {

  const { data, canvasWidthInHz, rangeWithUnits } = props;

  const container = React.createRef() as any;

  const rawFrequencyFrom = useMemo(() => {
    return convertToHz(rangeWithUnits[0]);
  }, [rangeWithUnits])

  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    setContainerHeight(0);
    d3.select(container.current).select('svg:first-child').remove();

    const containerWidth = container.current?.offsetWidth;
    const containerHeight = container.current?.offsetHeight;

    d3.select(container.current)
      .append('svg')
      .attr('height', containerHeight)
      .attr('width', containerWidth)
      .attr('viewbox', `0 0 ${containerHeight} ${containerWidth}`);

    setContainerHeight(containerHeight);
    setContainerWidth(containerWidth);
  }, []);

  useEffect(() => {
    d3.select(container.current)
      .select('svg')
      .attr('height', containerHeight)
      .attr('viewbox', `0 0 ${containerHeight} ${containerWidth}`);
  }, [containerHeight, containerWidth]);

  useEffect(() => {
    d3.select(container.current).select('svg').selectAll('*').remove();

    if (data.length && canvasWidthInHz && rangeWithUnits.length) {

      const oneHzInPx = containerWidth / canvasWidthInHz;

      const canvas = d3.select(container.current).select('svg').append('g');

      setContainerHeight(data.length * 180 + 15);
      data.forEach((technology, tIdx) => {

        canvas.append('text')
          .text(technology.technologyName)
          .attr('x', 20)
          .attr('y', (tIdx * 180) + 20)
          .attr('font-size', '10px')

        technology.channels.forEach((channel) => {
          createParabola(
            canvas,
            ((channel.centralFrequencyHz - (channel.bandwidth / 2)) * oneHzInPx - rawFrequencyFrom * oneHzInPx),
            channel.bandwidth * oneHzInPx,
            (tIdx * 180) + 30,
            150
          );

          canvas.append('line')
            .attr('x1', channel.centralFrequencyHz * oneHzInPx - rawFrequencyFrom * oneHzInPx)
            .attr('y1', (tIdx * 180) + 30)
            .attr('x2', channel.centralFrequencyHz * oneHzInPx - rawFrequencyFrom * oneHzInPx)
            .attr('y2', (tIdx * 180) + 180)
            .style('stroke', '#007DFF')

        })
      })

      const ticks = [extractNumber(rangeWithUnits[0]), extractNumber(rangeWithUnits[1])];

      const xscale = d3.scaleLinear().domain(ticks).range([0, containerWidth]);
      const x_axis = d3.axisBottom(xscale)
        .tickFormat((x: any, i) => (i === 0 || i === 10) ? '' : `${x} ${extractUnits(rangeWithUnits[0])}`)
        .tickSizeInner([containerHeight] as any)
        .tickPadding([(containerHeight - 5) * -1] as any)

      d3.select(container.current).select('svg').append('g').call(x_axis as any);

      d3.select(container.current)
        .selectAll('.tick line')
        .attr('stroke-dasharray', '2,2')
        .attr('color', 'gray')
    }

  }, [data, containerHeight, containerWidth]);

  function createParabola(canvas, X, width, Y, height) {

    const fn = function (x) {
      return Math.pow(x, 2);
    };

    const x = d3.scaleLinear([X, X + width]);
    const y = d3.scaleLinear([Y, Y + height]);

    const line = d3.line()
      .curve(d3.curveBasis)
      .x(function (d: any) {
        return x(d.x);
      })
      .y(function (d: any) {
        return y(d.y);
      });

    const data = d3.range(-10, 11).map(function (d) {
      return { x: d, y: fn(d) + Y };
    });

    x.domain(d3.extent(data, function (d) {
      return d.x;
    }) as number[]);
    y.domain([Y, d3.max(data, function (d) {
      return d.y;
    }) as number]);

    canvas.append('path')
      .datum(data)
      .attr('d', line as any)
      .attr('stroke', '#007DFF')
      .attr('fill', 'rgba(0, 125, 255, 0.5)')

  }

  return (
    <main
      ref={container}
      style={{
        width: '100%',
      }}
    />
  );
};

export default FrequencyAnalysisCanvas;