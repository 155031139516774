import { Redirect, Route, Switch } from 'react-router-dom';
import PAGES from '../../../pages';
import {
  LocationConfiguration
} from '../RegionAndCountryContainer';
import { USER_STATUS } from '../../../store/userListStore/userStatus';
import UserTable from '../UserTable';
import WorkflowPageContainer from '../Workflow/WorkflowPageContainer';
import { CheckPermission } from "../../../requests/CheckPermission";
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";
import ImportMainPanel from "../Import/ImportMainPanel";
import GlobalAppSettings from "../GlobalAppSettings";
import WirelessSystemSettingsList from "../WirelessSystemSettingsList";
import { GeneralRequest } from "../../../requests/GeneralRequest";
import { getAllRegions } from "../../../store/regionStore/regionRequests";
import TechnologyConfigurations from "../TechnologiesMainPanel/TechnologyConfigurations/TechnologyConfigurations";
import ResponsibleUnitSettingsList from "../ResponsibleUnitSettingsList";

const SettingsMainPanel = () => {
  return (
    <Switch>
      <Route exact path={PAGES.SETTINGS_PAGE}>
        <CheckPermission
          permissionName={PERMISSIONS.REGIONS_AND_COUNTRY_ACTIONS}
          noPermissionComponent={(
            <Redirect to={PAGES.TECHNOLOGIES} />
          )}
        >
          <Redirect to={PAGES.REGIONS_AND_COUNTRY} />
        </CheckPermission>
      </Route>
      <Route exact path={PAGES.REGIONS_AND_COUNTRY}>
        <GeneralRequest requestAction={getAllRegions}>
          <LocationConfiguration />
        </GeneralRequest>
      </Route>
      <Route exact path={PAGES.USER_MANAGEMENT}>
        <Redirect to={PAGES.USER_MANAGEMENT_ALL} />
      </Route>
      <Route exact path={PAGES.USER_MANAGEMENT_ALL}>
        <UserTable filter={USER_STATUS.ALL} />
      </Route>
      <Route exact path={PAGES.USER_MANAGEMENT_NEW}>
        <UserTable filter={USER_STATUS.NEW} />
      </Route>
      <Route exact path={PAGES.USER_MANAGEMENT_ACTIVATION}>
        <UserTable filter={USER_STATUS.ACTIVATION} />
      </Route>
      <Route exact path={PAGES.USER_MANAGEMENT_ACTIVE}>
        <UserTable filter={USER_STATUS.ACTIVE} />
      </Route>
      <Route exact path={PAGES.USER_MANAGEMENT_INACTIVE}>
        <UserTable filter={USER_STATUS.INACTIVE} />
      </Route>
      <Route exact path={PAGES.TECHNOLOGIES}>
        <TechnologyConfigurations />
      </Route>
      <Route exact path={PAGES.WIRELESS_SYSTEM}>
        <WirelessSystemSettingsList />
      </Route>
      <Route exact path={PAGES.RESPONSIBLE_UNITS}>
        <ResponsibleUnitSettingsList />
      </Route>
      <Route exact path={PAGES.WORKFLOW_MANAGEMENT}>
        <WorkflowPageContainer />
      </Route>
      <Route exact path={PAGES.IMPORT}>
        <ImportMainPanel />
      </Route>
      <Route exact path={PAGES.GLOBAL_SETTINGS}>
        <GlobalAppSettings />
      </Route>
      <Route>
        <Redirect to={PAGES.HOME} />
      </Route>
    </Switch>
  );
};

export default SettingsMainPanel;
