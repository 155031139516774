import React from "react";
import { useTypedDispatch } from "../../../app/state";
import { useSelector } from "react-redux";
import {
  selectAllResponsibleUnits,
  selectCurrentResponsibleUnitsId
} from "../../../store/responsibleUnitStore/responsibleUnitsSelectors";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { IconButton } from "@material-ui/core";
import { Toolbar } from "../../../app/components";
import { setCurrentResponsibleUnit } from "../../../store/responsibleUnitStore/responsibleUnitsState";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { GeneralRequest } from "../../../requests/GeneralRequest";
import { getAllResponsibleUnits } from "../../../store/responsibleUnitStore/responsibleUnitsRequests";
import MenuList from "../../../components/MenuList";

const ResponsibleUnitSettingsList = () => {
  const dispatch = useTypedDispatch();
  const allUnitList = useSelector(selectAllResponsibleUnits);
  const currentResponsibleUnitId = useSelector(selectCurrentResponsibleUnitsId);

  const onUnitSelect = (id) => {
    dispatch(setCurrentResponsibleUnit(id));
  }

  const onCreateNew = () => {
    onUnitSelect(-1);
  }

  return  (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title="Responsible units">
          <IconButton
            aria-label='add responsible unit'
            color='secondary'
            size='small'
            onClick={onCreateNew}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE}>
        <GeneralRequest requestAction={getAllResponsibleUnits}>
          <MenuList
            selectItem={onUnitSelect}
            selectedItemId={currentResponsibleUnitId}
            items={allUnitList}
          />
        </GeneralRequest>
      </HorizontalPanel>
    </HorizontalPanel>
  );
}

export default ResponsibleUnitSettingsList;