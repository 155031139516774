import { useState } from "react";
import cn from 'classnames';
import { useSelector } from "react-redux";
import { selectDeviceAllLoadingState, selectSearchDeviceList } from "../../../store/deviceStore/deviceSelectors";
import { Grid, IconButton } from "@material-ui/core";
import BallotOutlinedIcon from '@material-ui/icons/BallotOutlined';
import SearchTable from "../SearchTable";
import Loading from "../../../components/Loading/Loading";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import useNavigator from "../../../hooks/navigator.hook";
import ColumnsSelector, { ColumnItem } from "../../../components/ColumnsSelector";
import { DeviceColumnList } from "../../../components/DeviceTable/devicesColumns";

import classes from './index.module.scss';

const defaultColumns: DeviceColumnList = {
  showOrigin: true,
  showWirelessSystem: true,
  showRequester: false,
  showDeviceOwner: true,
  showDeviceOperator: false,
  showDeviceManager: true,
  showWirelessInterface: true,
  showLastUpdated: true,
}

const SearchCentralPanel = () => {
  const [ columnList, setColumnList ] = useState(defaultColumns);
  const { navigateToBulkEditPage } = useNavigator();
  const deviceList = useSelector(selectSearchDeviceList);
  const searchLoading = useSelector(selectDeviceAllLoadingState);

  const onBulkEdit = () => {
    const deviceIdList: number[] = [];
    deviceList.forEach((device) => device && deviceIdList.push(device.id));
    navigateToBulkEditPage({ deviceIdList });
  }

  const onSettingsChange = (type) => {
    const result = {
      ...columnList,
      [type]: !columnList[type],
    }

    setColumnList(result);
  }

  const columnsSettings: ColumnItem[] = [
    {
      title: "Origin",
      type: "showOrigin",
      value: columnList.showOrigin || false,
    },
    {
      title: "Wireless system",
      type: "showWirelessSystem",
      value: columnList.showWirelessSystem || false,
    },
    {
      title: "Requester",
      type: "showRequester",
      value: columnList.showRequester || false,
    },
    {
      title: "Device Owner",
      type: "showDeviceOwner",
      value: columnList.showDeviceOwner || false,
    },
    {
      title: "Operator",
      type: "showDeviceOperator",
      value: columnList.showDeviceOperator || false,
    },
    {
      title: "Frequency Manager",
      type: "showDeviceManager",
      value: columnList.showDeviceManager || false,
    },
    {
      title: "Wireless Interface",
      type: "showWirelessInterface",
      value: columnList.showWirelessInterface || false,
    },
    {
      title: "Last Updated",
      type: "showLastUpdated",
      value: columnList.showLastUpdated || false,
    },
  ]

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE} className={classes.container}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={`Search results (${deviceList.length})`}>
          <IconButton
            disabled={deviceList.length === 0}
            aria-label='bulk edit'
            color='secondary'
            size='small'
            onClick={onBulkEdit}
          >
            <BallotOutlinedIcon />
          </IconButton>
          <ColumnsSelector items={columnsSettings} onSelect={onSettingsChange} />
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} className={classes.container}>
        <Grid container className={cn(classes.tableContainer, {
          [classes.width4K]: deviceList.length > 0,
        })}>
          {
            searchLoading.loading ? <Loading /> : <SearchTable columnList={columnList} />
          }
        </Grid>
      </HorizontalPanel>
    </HorizontalPanel>
  )
}

export default SearchCentralPanel;