import React, { useEffect, useState } from "react";
import { DataGrid, DataGridProps, GridColDef } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const MARGIN = 16;

function getWidth(node: Element) {
  return [].reduce.call(node.childNodes, ((sum, node) => sum + node.scrollWidth), 0)
}

const StretchedDataGrid = ({ columns, ...props }: DataGridProps) => {
  const ref = React.createRef() as any;
  const [ state, setState ] = useState({ mappedColumns: [] as GridColDef[], resized: false });

  useEffect(() => {
    setState({ mappedColumns: [], resized: false })
  }, [ columns ]);

  const { mappedColumns, resized } = state;

  const autoSizeColumns = () => {
    const domRows = [ ...(ref.current?.querySelectorAll('.MuiDataGrid-row') || []) ];
    const domReady = (props.rows?.length === 0) || domRows.length;

    if (!domReady) {
      setTimeout(autoSizeColumns, 15);
      return;
    }

    if (props.rows.length === 0) {
      setState({
        mappedColumns: columns,
        resized: true,
      });

      return;
    }

    const mappedColumns = [];

    columns
      .forEach((col, idx) => {

        const maxContentWidth = domRows
          .reduce((previousMax, dR) => {
            return Math.max(previousMax, getWidth(dR.childNodes[props.checkboxSelection ? idx + 1 : idx]) + MARGIN * 2)
          }, 0);

        const newColumnData: GridColDef = {
          ...col,
        }

        if (!col.width) {
          newColumnData.width = maxContentWidth;
          delete newColumnData.flex;
        }

        // @ts-ignore
        mappedColumns.push(newColumnData);
      });

    setState({ mappedColumns, resized: true });
  }

  return (
    <>
      {!resized && (
        <>
          <Grid container alignItems="center" justifyContent="center" style={{
            height: '100%',
            position: 'absolute',
            opacity: 0.8,
            backgroundColor: 'white',
            textAlign: 'center',
            zIndex: 1
          }}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
          <DataGrid
            ref={ref}
            onResize={autoSizeColumns}
            columns={columns}
            {...props /* eslint-disable-line react/jsx-props-no-spreading */}
          />
        </>
      )}
      {
        resized && (
          <DataGrid
            ref={ref}
            onResize={autoSizeColumns}
            columns={mappedColumns}
            {...props /* eslint-disable-line react/jsx-props-no-spreading */}
          />
        )
      }
    </>
  );
}

export default StretchedDataGrid;