import React from "react";
import { FormikProps } from "formik";
import { Dropdown } from "../../app/components/Dropdown";
import { Grid, TextField } from "@material-ui/core";
import { DeviceFormikInterface } from "../DeviceForm/formikInterface";
import { DeviceTypeEnum } from "../../models/enums/DeviceType.enum";
import { useShowModal } from "../../app/shared/modal-context/modal.context";
import { ModalType } from "../../app/shared/modal-context/constants";

type DeviceTypeProps = {
  hasMapPoint?: boolean,
  formDisabled: boolean,
  deviceFormik: FormikProps<DeviceFormikInterface>
}

const DeviceTypeInput = ({ hasMapPoint, formDisabled, deviceFormik }: DeviceTypeProps) => {
  const { showModal } = useShowModal();
  const isStationary = deviceFormik.values?.type + '' === DeviceTypeEnum.STATIONARY;
  const numberOfDevices = isStationary ? 1 : deviceFormik.values.numberOfDevices;
  const typesForMultiple = [ DeviceTypeEnum.PORTABLE, DeviceTypeEnum.GROUP ];

  const onTypeChange = (e) => {
    if (!hasMapPoint) {
      deviceFormik.handleChange(e);
      return;
    }
    const prevValue = deviceFormik.values.type + '' as DeviceTypeEnum;
    const value = e.target.value + '' as DeviceTypeEnum;
    if ((value === DeviceTypeEnum.STATIONARY && typesForMultiple.includes(prevValue))
      || (typesForMultiple.includes(value) && prevValue === DeviceTypeEnum.STATIONARY)) {
      showModal(ModalType.MODAL_GENERIC_CONFIRM, {
        title: 'Change device type',
        text: 'All points on map and number of devices will be erased on save. Do you want to change current device type?',
        confirmButtonText: 'Change type',
        confirmButtonHandler: () => {
          deviceFormik.handleChange(e);
        },
      })
    } else {
      deviceFormik.handleChange(e);
    }
  }
  const toIntOnBlur = (e) => {
    const value = e.target.value;
    const intValue = parseInt(value, 10);
    e.target.value = isNaN(intValue) ? 1 : intValue;
    deviceFormik.handleChange(e);
  }

  return (
    <>
      <Grid item xs={4}>
        <Dropdown
          disabled={formDisabled}
          values={[
            {
              id: 0,
              name: 'Stationary',
            },
            {
              id: 1,
              name: 'Portable',
            },
            {
              id: 2,
              name: 'Group'
            },
          ]}
          label='Type'
          name='type'
          fullWidth
          valueChange={onTypeChange}
          selectedValue={+deviceFormik.values?.type}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label='Number of devices'
          required
          type="number"
          variant='outlined'
          name='numberOfDevices'
          inputProps={{
            step: 1,
          }}
          fullWidth
          onBlur={toIntOnBlur}
          disabled={formDisabled || isStationary}
          value={numberOfDevices}
          onChange={deviceFormik.handleChange}
          helperText={deviceFormik.errors.numberOfDevices}
          error={Boolean(deviceFormik.errors.numberOfDevices)}
        />
      </Grid>
    </>
  )
}

export default DeviceTypeInput;