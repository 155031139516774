import React from "react";
import { useSelector } from "react-redux";
import { selectAllResponsibleUnits } from "../../../store/responsibleUnitStore/responsibleUnitsSelectors";
import { FormikProps } from "formik";
import { DeviceFormikInterface } from "../../../components/DeviceForm/formikInterface";
import { TemplateFormikInterface } from "../../TemplatesPage/TemplateCentralPanel/TemplateFormikInterface";
import { SearchFormInterface } from "../../SearchPage/SearchForm/SearchFormInterface";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

import classes from './index.module.scss';

type ResponsibleUnitDropdownProps = {
  formikProps: FormikProps<DeviceFormikInterface | TemplateFormikInterface | SearchFormInterface>
  formDisabled?: boolean;
  addEmpty?: boolean;
}

const ResponsibleUnitDropdown = (props: ResponsibleUnitDropdownProps) => {
  const unitList = useSelector(selectAllResponsibleUnits);
  const { formikProps, formDisabled = false, addEmpty = false } = props;

  const onChange = (event: any, newValue: { name: string, id: number }) => {
    formikProps.setFieldValue('responsibleUnitId', newValue.id);
  }

  const options = [
    ...(addEmpty ?
        [
          {
            id: -1,
            name: '',
          },
          {
            id: 0,
            name: 'None',
          }
        ] :
        [
          {
            id: 0,
            name: 'None',
          }
        ]
    ),
    ...unitList
  ];

  const currentOption = options.find((option) => option.id === formikProps.values?.responsibleUnitId) || options[0];

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            className: classes.input
          }}
          label='Responsible unit'
          name='responsibleUnitId'
          variant='outlined'
        />
      )}
      fullWidth
      disableClearable={true}
      disabled={formDisabled}
      value={currentOption}
      onChange={onChange}
      options={[
        {
          id: 0,
          name: 'None',
        },
        ...unitList
      ]}
      getOptionLabel={(option) => {
        return option.name || ''
      }}
    />
  )
}

export default ResponsibleUnitDropdown;