import React, { useMemo } from "react";
import { DeviceStatus } from "../../../models/enums/DeviceStatus.enum";
import { useSelector } from "react-redux";
import Chip from "@material-ui/core/Chip";
import { selectAllCountries } from "../../../store/countryStore/countrySelectors";
import { selectAllLocations } from "../../../store/locationStore/locationSelectors";
import { selectAllBuildings } from "../../../store/buildingStore/buildingsSelectors";
import { selectAllFloors } from "../../../store/floorStore/floorSelectors";
import {
  getSearchManagerList, getSearchOperatorList,
  getSearchOwnerList,
  getSearchRequestersList
} from "../../../store/userListStore/userListSelectors";
import { selectAllWirelessSystems } from "../../../store/wirelessSystemStore/wirelessSystemSelectors";
import {
  selectAllTechnologyConfigurations
} from "../../../store/technologyConfigurationStore/technologyConfigurationSelectors";
import { useFormik } from "formik";
import * as yup from "yup";
import { isEmpty } from "lodash";
import { Grid } from "@material-ui/core";
import { Dropdown } from "../../../app/components/Dropdown";
import Multiselect from "../../../components/Multiselect";
import UnitsInput from "../../../components/TextFields/UnitsInput";
import { useTypedDispatch } from "../../../app/state";
import { clearIdList } from "../../../store/deviceStore/deviceState";
import useNavigator from "../../../hooks/navigator.hook";
import SearchQueryParams from "../../../routes/SearchQueryParams";
import { selectAllDeviceTags } from "../../../store/deviceTagsStore/deviceTagsSelector";

import classes from './index.module.scss';
import SelectAndAction from "../../../components/TextFields/SelectAndAction/SelectAndAction";
import ResponsibleUnitDropdown from "../../DevicesPage/ResponsibleUnitDropdown";
import { SearchFormInterface } from "./SearchFormInterface";

const DEVICE_STATUSES = [
  DeviceStatus.NEW,
  DeviceStatus.REQUESTED,
  DeviceStatus.APPROVED,
  DeviceStatus.REJECTED,
  DeviceStatus.ACTIVE,
  DeviceStatus.RETIRED,
];

const SearchForm = ({ searchObject }: { searchObject?: SearchQueryParams }) => {
  const dispatch = useTypedDispatch();
  const { navigateToSearchPage } = useNavigator();
  const countryList = useSelector(selectAllCountries);
  const locationList = useSelector(selectAllLocations);
  const buildingList = useSelector(selectAllBuildings);
  const floorList = useSelector(selectAllFloors);
  const requesterList = useSelector(getSearchRequestersList) || [];
  const ownerList = useSelector(getSearchOwnerList) || [];
  const managerList = useSelector(getSearchManagerList) || [];
  const operatorList = useSelector(getSearchOperatorList) || [];
  const wirelessSystemList = useSelector(selectAllWirelessSystems);
  const technologyList = useSelector(selectAllTechnologyConfigurations);
  const tagList = useSelector(selectAllDeviceTags);

  const initialValues: SearchFormInterface = {
    countryId: searchObject?.countryId || null,
    locationId: searchObject?.locationId || null,
    buildingList: searchObject?.buildings || [] as number[],
    floorList: searchObject?.floors || [] as number[],
    responsibleUnitId: searchObject?.responsibleUnitId || -1,
    frequencyFrom: searchObject?.frequencyFrom || '',
    frequencyTo: searchObject?.frequencyTo || '',
    technologies: searchObject?.technology || [] as number[],
    wirelessSystemId: searchObject?.wirelessSystems || [] as number[],
    requesterId: searchObject?.requesters || [] as number[],
    ownerId: searchObject?.owners || [] as number[],
    managerId: searchObject?.managers || [] as number[],
    operatorId: searchObject?.operators || [] as number[],
    status: searchObject?.status || [] as DeviceStatus[],
    tagList: searchObject?.tags || [],
  };

  const searchFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema: yup.object({
      frequencyFrom: yup.string().matches(/^\d*\.?\d+ +(nHz|Hz|MHz|GHz|kHz|µHz|THz)$/, 'Invalid value'),
      frequencyTo: yup.string().matches(/^\d*\.?\d+ +(nHz|Hz|MHz|GHz|kHz|µHz|THz)$/, 'Invalid value'),
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const availableTechnologyList = technologyList.filter((technologyConfiguration) => {
    const countryIdList = technologyConfiguration.countries.map((country) => country.id);

    return searchFormik.values.countryId ? countryIdList.includes(searchFormik.values.countryId || 0) : true;
  });

  const onSearch = () => {
    const params = {
      countryId: searchFormik.values.countryId,
      locationId: searchFormik.values.locationId,
      buildings: searchFormik.values.buildingList.length === 0 ? null : searchFormik.values.buildingList,
      floors: searchFormik.values.floorList.length === 0 ? null : searchFormik.values.floorList,
      responsibleUnitId: searchFormik.values.responsibleUnitId === -1 ? null : searchFormik.values.responsibleUnitId,
      frequencyFrom: searchFormik.values.frequencyFrom,
      frequencyTo: searchFormik.values.frequencyTo,
      technology: searchFormik.values.technologies.length === 0 ? null : searchFormik.values.technologies,
      wirelessSystems: searchFormik.values.wirelessSystemId.length === 0 ? null : searchFormik.values.wirelessSystemId,
      requesters: searchFormik.values.requesterId.length === 0 ? null : searchFormik.values.requesterId,
      owners: searchFormik.values.ownerId.length === 0 ? null : searchFormik.values.ownerId,
      managers: searchFormik.values.managerId.length === 0 ? null : searchFormik.values.managerId,
      operators: searchFormik.values.operatorId.length === 0 ? null : searchFormik.values.operatorId,
      status: searchFormik.values.status.length === 0 ? null : searchFormik.values.status as string[],
      tags: searchFormik.values.tagList.length === 0 ? null : searchFormik.values.tagList,
    }

    navigateToSearchPage(params);
  }

  const onSearchClick = async () => {
    const valid = await searchFormik.validateForm();
    if (isEmpty(valid) && !isEmpty(searchFormik.values)) {
      onSearch();
    }
  }

  const onClear = () => {
    searchFormik.setValues(searchFormik.initialValues);
    navigateToSearchPage({});
    dispatch(clearIdList());
  }

  const onDeleteKey = (key, id) => {
    searchFormik.setFieldValue(
      key,
      searchFormik.values[key].filter((item) => item !== id)
    )
  };

  const onCountryChange = (e) => {
    searchFormik.setFieldValue('locationId', '');
    searchFormik.setFieldValue('technologies', []);
    searchFormik.setFieldValue('buildingList', []);
    searchFormik.setFieldValue('floorList', []);
    searchFormik.handleChange(e);
  }

  const onLocationChange = (e) => {
    searchFormik.setFieldValue('buildingList', []);
    searchFormik.setFieldValue('floorList', []);
    searchFormik.handleChange(e);
  }

  const locationFilteredList = useMemo(() => {
    return locationList.filter((location) => location.countryId === searchFormik?.values?.countryId);
  }, [ searchFormik?.values?.countryId, locationList ]);

  const buildingFilteredList = useMemo(() => {
    return Multiselect.getValues(buildingList.filter((building) => building.locationId === searchFormik?.values?.locationId), 'id', 'name');
  }, [ searchFormik?.values?.locationId, buildingList ]);

  const floorFilteredList = useMemo(() => {
    return Multiselect.getValues(floorList.filter((floor) => searchFormik?.values?.buildingList.includes(floor.buildingId)), 'id', 'name');
  }, [ searchFormik?.values?.buildingList, floorList ]);

  const requesterMenuList = useMemo(() => {
    return Multiselect.getValues(requesterList, 'id', 'profile.fullName');
  }, [ requesterList ]);

  const ownerMenuList = useMemo(() => {
    return Multiselect.getValues(ownerList, 'id', 'profile.fullName');
  }, [ ownerList ]);

  const managerMenuList = useMemo(() => {
    return Multiselect.getValues(managerList, 'id', 'profile.fullName');
  }, [ managerList ]);

  const operatorMenuList = useMemo(() => {
    return Multiselect.getValues(operatorList, 'id', 'profile.fullName');
  }, [ operatorList ]);

  const wirelessSystemMenuList = useMemo(() => {
    return Multiselect.getValues(wirelessSystemList, 'id', 'name');
  }, [ wirelessSystemList ]);

  const technologyMenuList = useMemo(() => {
    return Multiselect.getValues(availableTechnologyList, 'id', 'name');
  }, [ availableTechnologyList ]);

  const statusMenuList = DEVICE_STATUSES.map((status) => ({
    id: status,
    value: status.charAt(0).toUpperCase() + status.slice(1)
  }));

  const tagMenuList = useMemo(() => {
    return tagList.map((tag) => {
      return {
        valueOption: tag.id,
        title: tag.name,
      }
    })
  }, [tagList])

  return (
    <Grid container direction='row' spacing={2} className={classes.searchContainer}>
      <Grid item xs={12}>
        <Dropdown
          name='countryId'
          fullWidth
          label={'Country'}
          values={countryList}
          valueChange={onCountryChange}
          selectedValue={searchFormik?.values?.countryId}
          error={searchFormik?.errors?.countryId}
        />
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          name='locationId'
          fullWidth
          label={'Location'}
          values={locationFilteredList}
          valueChange={onLocationChange}
          selectedValue={searchFormik?.values?.locationId}
          disabled={!searchFormik?.values?.countryId}
          error={searchFormik?.errors?.locationId}
        />
      </Grid>
      <Grid item xs={12}>
        <Multiselect
          title='Building'
          name='buildingList'
          fullWidth
          items={buildingFilteredList}
          maxChipLength={30}
          // @ts-ignore
          selectedItems={searchFormik.values.buildingList.map((id) => buildingFilteredList.find((building) => building.id === id)).filter(Boolean)}
          onChange={(values) => {
            searchFormik.setFieldValue('buildingList', values)
          }}
          disabled={!searchFormik?.values?.locationId}
          onDelete={({ id }) => {
            onDeleteKey('buildingList', id);
            searchFormik.setFieldValue('floorList', []);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Multiselect
          title='Floor'
          name='floorList'
          fullWidth
          maxChipLength={30}
          items={floorFilteredList}
          // @ts-ignore
          selectedItems={searchFormik.values.floorList.map((id) => floorFilteredList.find((floor) => floor.id === id)).filter(Boolean)}
          onChange={(values) => {
            searchFormik.setFieldValue('floorList', values);
          }}
          disabled={!searchFormik?.values?.buildingList.length}
        />
      </Grid>
      <Grid item xs={12}>
        <ResponsibleUnitDropdown
          formikProps={searchFormik}
          addEmpty={true}
        />
      </Grid>
      <Grid item xs={12}>
        <UnitsInput
          unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
          fullWidth
          name='frequencyFrom'
          variant='outlined'
          label={'Frequency range from'}
          defaultValue={''}
          onChange={searchFormik?.handleChange}
          value={searchFormik?.values?.frequencyFrom}
          error={Boolean(searchFormik?.errors?.frequencyFrom)}
          helperText={searchFormik?.errors?.frequencyFrom}
        />
      </Grid>
      <Grid item xs={12}>
        <UnitsInput
          unitList={UnitsInput.UNIT_TEMPLATE.FREQUENCY}
          fullWidth
          name='frequencyTo'
          variant='outlined'
          label={'Frequency range to'}
          defaultValue={''}
          onChange={searchFormik?.handleChange}
          value={searchFormik?.values?.frequencyTo}
          error={Boolean(searchFormik?.errors?.frequencyTo)}
          helperText={searchFormik?.errors?.frequencyTo}
        />
      </Grid>
      <Grid item xs={12}>
        <Multiselect
          title='Technology'
          name='technologies'
          fullWidth
          maxChipLength={30}
          items={technologyMenuList}
          // @ts-ignore
          selectedItems={searchFormik.values.technologies.map((id) => technologyMenuList.find((technology) => technology.id === id)).filter(Boolean)}
          onChange={(values) => {
            searchFormik.setFieldValue('technologies', values);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Multiselect
          title='Wireless System'
          name='wirelessSystemId'
          fullWidth
          maxChipLength={30}
          items={wirelessSystemMenuList}
          // @ts-ignore
          selectedItems={searchFormik.values.wirelessSystemId.map((id) => wirelessSystemMenuList.find((wirelessSystem) => wirelessSystem.id === id)).filter(Boolean)}
          onChange={(values) => {
            searchFormik.setFieldValue('wirelessSystemId', values);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Multiselect
          title='Status'
          name='status'
          fullWidth
          items={statusMenuList}
          // @ts-ignore
          selectedItems={searchFormik.values?.status?.map((id) => statusMenuList.find((status) => status.id === id)).filter(Boolean)}
          onChange={(values) => {
            searchFormik.setFieldValue('status', values);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Multiselect
          title='Requester'
          name='requesterId'
          fullWidth
          maxChipLength={30}
          items={requesterMenuList}
          // @ts-ignore
          selectedItems={searchFormik.values.requesterId.map((id) => requesterMenuList.find((requester) => requester.id === id)).filter(Boolean)}
          onChange={(values) => {
            searchFormik.setFieldValue('requesterId', values);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Multiselect
          title='Owner'
          name='ownerId'
          fullWidth
          maxChipLength={30}
          items={ownerMenuList}
          // @ts-ignore
          selectedItems={searchFormik.values.ownerId.map((id) => ownerList.find((owner) => owner.id === id)).filter(Boolean)}
          onChange={(values) => {
            searchFormik.setFieldValue('ownerId', values);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Multiselect
          title='Frequency manager'
          name='managerId'
          fullWidth
          maxChipLength={30}
          items={managerMenuList}
          // @ts-ignore
          selectedItems={searchFormik.values.managerId.map((id) => managerList.find((manager) => manager.id === id)).filter(Boolean)}
          onChange={(values) => {
            searchFormik.setFieldValue('managerId', values);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Multiselect
          title='Operator'
          name='operatorId'
          fullWidth
          maxChipLength={30}
          items={operatorMenuList}
          // @ts-ignore
          selectedItems={searchFormik.values.operatorId.map((id) => operatorList.find((operator) => operator.id === id)).filter(Boolean)}
          onChange={(values) => {
            searchFormik.setFieldValue('operatorId', values);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectAndAction
          label='Request number'
          name='tagList'
          currentValue={searchFormik.values?.tagList[0]}
          isAddNewAvailable={false}
          listFields={tagMenuList}
          onChange={(value) => {
            searchFormik.setFieldValue('tagList', [value]);
          }}
        />
      </Grid>
      <Grid item xs={6} container justifyContent='flex-start'>
        <Chip
          label="Search"
          color={'primary'}
          onClick={onSearchClick}
        />
      </Grid>
      <Grid item xs={6} container justifyContent='flex-end'>
        <Chip
          label="Clear"
          color={'default'}
          onClick={onClear}
        />
      </Grid>
    </Grid>
  )
}

export default SearchForm;