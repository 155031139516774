import React, { useState } from "react";
import { FormControl, IconButton, ListItemIcon, MenuItem, Select } from "@material-ui/core";
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import TuneIcon from '@material-ui/icons/Tune';

import classes from "./index.module.scss";

export type ColumnItem = {
  title: string;
  type: string;
  value: boolean;
}

interface ColumnProps {
  items: ColumnItem[],
  onSelect: (type) => void,
}

const ColumnsSelector = (props: ColumnProps) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const { items, onSelect } = props;

  const toggleOpen = (open) => () => {
    setIsOpen(open)
  }

  const onClick = (type) => () => {
    onSelect(type);
    toggleOpen(true)();
  }

  return (
    <FormControl>
      <IconButton
        aria-label='configure table columns'
        color='secondary'
        size='small'
      >
        <TuneIcon />
      </IconButton>
      <Select
        open={isOpen}
        onClose={toggleOpen(false)}
        onOpen={toggleOpen(true)}
        className={classes.select}
        value={''}
      >
        {
          items.map((singleItem, index) => {
            return (
              <MenuItem
                value={index}
                onClick={onClick(singleItem.type)}
                key={singleItem.type}
              >
                <ListItemIcon>
                  {
                    singleItem.value ?
                      <ToggleOnIcon className={classes.on} /> :
                      <ToggleOffIcon className={classes.off} />
                  }
                </ListItemIcon>
                {singleItem.title}
              </MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  )
}

export default ColumnsSelector;