import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../app/api";
import DeviceModel from "../../models/DeviceModel";
import UnsavedWirelessInterfaceModel from "../../models/UnsavedWirelessInterfaceModel";
import { convertToHz } from "../../helpers/converters/frequency";
import { SearchInterface } from "./searchInterface";
import { serializeError } from "../../app/shared";
import DeviceTemplateModel from "../../models/DeviceTemplateModel";
import SearchQueryParams from "../../routes/SearchQueryParams";
import { paramsSerializer } from "../../helpers/request";

const SLICE_NAME = "deviceList";

export const requestAllDevicesByQuery = createAsyncThunk(
  `${SLICE_NAME}/get-all`,
  async (query: { mapId?: number, deviceIdList?: number[] }, { rejectWithValue }) => {
    try {
      const deviceList: DeviceModel[] = await request.get(
        "/device",
        {
          params: query || {},
          paramsSerializer,
        }
      );
      return deviceList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const requestDeviceById = createAsyncThunk(
  `${SLICE_NAME}/get-by-id`,
  async (id: number, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.get(
        "/device/" + id
      );
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const addWirelessInterfaceToDevice = createAsyncThunk(
  `${SLICE_NAME}/add-wireless-interface`,
  async ({
    deviceId,
    technologyConfigurationId
  }: { deviceId: number, technologyConfigurationId: number }, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.post('/device/wireless-interface', {
        deviceId,
        technologyList: [ technologyConfigurationId ],
      });
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const createNewDevice = createAsyncThunk(
  `${SLICE_NAME}/create`,
  async (payload: unknown, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.post(`/device`, payload);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const updateDevice = createAsyncThunk(
  `${SLICE_NAME}/update`,
  async (payload: unknown, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.put(`/device`, payload);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const submitDevice = createAsyncThunk(
  `${SLICE_NAME}/submit`,
  async (id: number | undefined | null, { rejectWithValue }) => {
    try {
      const info: { device?: DeviceModel, errorCode: string } = await request.post(`/device/${id}/submit`);
      return info;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteDevice = createAsyncThunk(
  `${SLICE_NAME}/delete`,
  async (id: number | null, { rejectWithValue }) => {
    try {
      const data: { device: DeviceModel | null } = await request.delete(`/device/${id}`);
      const device = data?.device;
      return { device, deviceId: id };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const assignDevice = createAsyncThunk(
  `${SLICE_NAME}/assign`,
  async ({ deviceId, userIdList }: { deviceId: number, userIdList: number[] }, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.post(`/device/${deviceId}/assign`, { userIdList });
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const approveDevice = createAsyncThunk(
  `${SLICE_NAME}/approve`,
  async ({ id, reason }: { id: number, reason: string }, { rejectWithValue }) => {
    try {
      const info: { device?: DeviceModel, errorCode: string } = await request.post(`/device/${id}/approve`, { reason });
      return info;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const rejectDevice = createAsyncThunk(
  `${SLICE_NAME}/reject`,
  async ({ id, reason }: { id: number, reason: string }, { rejectWithValue }) => {
    try {
      const info: { device?: DeviceModel, errorCode: string } = await request.post(`/device/${id}/reject`, { reason });
      return info;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const deleteWirelessInterface = createAsyncThunk(
  `${SLICE_NAME}/delete-wireless-interface`,
  async (id: number, { rejectWithValue }) => {
    try {
      const data: { device?: DeviceModel, template?: DeviceTemplateModel } = await request.delete('/wireless-interface', { data: { id } });
      return data;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const updateWirelessInterface = createAsyncThunk(
  `${SLICE_NAME}/update-wireless-interface`,
  async (body: UnsavedWirelessInterfaceModel, { rejectWithValue }) => {
    try {
      const data: { device?: DeviceModel, template?: DeviceTemplateModel } = await request.put('/wireless-interface', body);
      return data;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const doSearch = createAsyncThunk(
  `${SLICE_NAME}/search`,
  async (query: SearchQueryParams, { rejectWithValue }) => {
    const searchQuery: SearchInterface = {
      countryId: query.countryId,
      locationId: query.locationId,
      buildingList: query.buildings,
      floorList: query.floors,
      responsibleUnitId: query.responsibleUnitId,
      technologies: query.technology,
      wirelessSystemId: query.wirelessSystems,
      requesterId: query.requesters,
      ownerId: query.owners,
      managerId: query.managers,
      operatorId: query.operators,
      status: query.status,
      tagList: query.tags,
    };
    if (query.frequencyFrom) {
      searchQuery.frequencyFrom = convertToHz(query.frequencyFrom)
    }
    if (query.frequencyTo) {
      searchQuery.frequencyTo = convertToHz(query.frequencyTo)
    }
    try {
      const response: { devices: DeviceModel[] } = await request.get(`/search`, {
        params: searchQuery,
        paramsSerializer,
      });
      return response.devices;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);


export const requestMyRequests = createAsyncThunk(
  `${SLICE_NAME}/requests`,
  async (_, { rejectWithValue }) => {
    let myDevicesList: DeviceModel[];
    let myWorkflowList: DeviceModel[];

    try {
      myDevicesList = await request.get('/request/me');
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }

    try {
      myWorkflowList = await request.get('/request/workflow');
    } catch (error) {
      return {
        myDevicesList,
        myWorkflowList: [],
      }
    }

    return {
      myDevicesList,
      myWorkflowList,
    }
  },
);

export const upsertDevicePoint = createAsyncThunk(
  'point/device/create',
  async (devicePoint: { deviceId: number, point: { x: number, y: number } }, { rejectWithValue }) => {
    try {
      const { device }: { device: DeviceModel } = await request.post('/point/device/', devicePoint);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteDevicePoint = createAsyncThunk(
  'point/device/delete',
  async (deviceId: number, { rejectWithValue }) => {
    try {
      const { device }: { device: DeviceModel } = await request.delete(`/point/device/${deviceId}`);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const saveDevicePath = createAsyncThunk(
  'path/device/create',
  async (devicePoint: { deviceId: number, path: ({ x: number, y: number })[] }, { rejectWithValue }) => {
    try {
      const { device }: { device: DeviceModel } = await request.post('/point/device/path', devicePoint);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteDevicePath = createAsyncThunk(
  'path/device/delete',
  async (deviceId: number, { rejectWithValue }) => {
    try {
      const { device }: { device: DeviceModel } = await request.delete(`/point/device/${deviceId}/path`);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const copyFromTemplate = createAsyncThunk(
  `${SLICE_NAME}/copyFromDevice`,
  async (body: { deviceId: number, templateId: number }, { rejectWithValue }) => {
    try {
      const { device }: { device: DeviceModel } = await request.post('/device/template', body);
      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const bulkSubmitValidation = createAsyncThunk(
  `${SLICE_NAME}/validate/bulk/submit`,
  async (body: { deviceIdList: number[] }, { rejectWithValue }) => {
    try {
      const invalidDeviceIdList: number[] = await request.post('/validate/submit/bulk', body);
      return invalidDeviceIdList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const bulkApproveValidation = createAsyncThunk(
  `${SLICE_NAME}/validate/bulk/approve`,
  async (body: { deviceIdList: number[] }, { rejectWithValue }) => {
    try {
      const invalidDeviceIdList: number[] = await request.post('/validate/approve/bulk', body);
      return invalidDeviceIdList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const bulkRejectValidation = createAsyncThunk(
  `${SLICE_NAME}/validate/bulk/reject`,
  async (body: { deviceIdList: number[] }, { rejectWithValue }) => {
    try {
      const invalidDeviceIdList: number[] = await request.post('/validate/reject/bulk', body);
      return invalidDeviceIdList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const bulkSubmit = createAsyncThunk(
  `${SLICE_NAME}/bulk/submit`,
  async (body: { deviceIdList: number[], operationText: string }, { rejectWithValue }) => {
    try {
      const resp: { deviceIdList: number[], tagModelId?: number } = await request.post('/device/submit/bulk', body);
      const invalidDeviceIdList = resp.deviceIdList;
      const tagModelId = resp.tagModelId;

      return { invalidDeviceIdList, tagModelId };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const bulkApprove = createAsyncThunk(
  `${SLICE_NAME}/bulk/approve`,
  async (body: { deviceIdList: number[], operationText: string }, { rejectWithValue }) => {
    try {
      const resp: { deviceIdList: number[], tagModelId?: number } = await request.post('/device/approve/bulk', body);
      const invalidDeviceIdList = resp.deviceIdList;
      const tagModelId = resp.tagModelId;

      return { invalidDeviceIdList, tagModelId };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const bulkReject = createAsyncThunk(
  `${SLICE_NAME}/bulk/reject`,
  async (body: { deviceIdList: number[], operationText: string }, { rejectWithValue }) => {
    try {
      const resp: { deviceIdList: number[], tagModelId?: number } = await request.post('/device/reject/bulk', body);
      const invalidDeviceIdList = resp.deviceIdList;
      const tagModelId = resp.tagModelId;

      return { invalidDeviceIdList, tagModelId };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const updateDeviceOwners = createAsyncThunk(
  `${SLICE_NAME}/device/owners`,
  async (body: { deviceId: number, userIdList: number[] }, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.post(`/device/${body.deviceId}/owners`, {
        userIdList: body.userIdList
      });

      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
)

export const updateDeviceOperators = createAsyncThunk(
  `${SLICE_NAME}/device/operators`,
  async (body: { deviceId: number, userIdList: number[] }, { rejectWithValue }) => {
    try {
      const device: DeviceModel = await request.post(`/device/${body.deviceId}/operators`, {
        userIdList: body.userIdList
      });

      return device;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const checkDevicePermissions = createAsyncThunk(
  `${SLICE_NAME}/device/permissions`,
  async (deviceId: number, { rejectWithValue }) => {
    try {
      const result: { canEdit: boolean, canDelete: boolean } = await request.post(`/permissions/${deviceId}/device`);

      return result;
    } catch (error) {
      return {
        canEdit: false,
        canDelete: false,
      }
    }
  }
);