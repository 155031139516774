import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from 'app/api';
import { serializeError } from 'app/shared';

export const getUserByToken = createAsyncThunk(
  `/auth/verify`,
  async (
    { token }: { token: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await request.post(
        `/auth/verify`,
        { token },
      );
      const { user, roles } = response as any;
      return { user, roles };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const loginWithPassword = createAsyncThunk(
  'auth/login',
  async (
    { password, login }: { login?: String; password?: String },
    { rejectWithValue },
  ) => {
    try {
      const response = await request.post(
        `/auth/login`,
        { password, login },
      );
      const { user, roles, token } = response as any;
      // todo: add check status
      if (user.status === 'active') {
        return { user, roles, token };
      }
      rejectWithValue({ statusText: 'no-access' });
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const logoutRequest = createAsyncThunk(
  'auth/logout',
  async (
    _,
    { rejectWithValue },
  ) => {
    try {
      return request.post(
        `/auth/logout`
      );
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const checkPermission = createAsyncThunk(
  `/permissions`,
  async (
    { token, permissionName }: { token: string, permissionName: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await request.post(
        `/permissions`,
        { token, type: permissionName },
      );

      return {
        [permissionName]: (response as any)[permissionName],
      };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const attachTokenToUser = createAsyncThunk(
  `/user/password/set`,
  async (
    { token, password }: { token: string, password: string },
    { rejectWithValue },
  ) => {
    try {
      await request.post(
        `/user/password/set`,
        { token, password },
      );

      return {
        success: true,
      };
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const loginWithSSO = createAsyncThunk(
  `/login/sso`,
  async (
    { code }: { code: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await request.post(
        `/auth/sso`,
        { code },
      );

      const { user, roles, token } = response as any;
      // todo: add check status
      if (user.status === 'active') {
        return { user, roles, token };
      }
      rejectWithValue({ statusText: 'no-access' });
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const searchUser = createAsyncThunk(
  `/search`,
  async (
    search: string,
    { rejectWithValue },
  ) => {
    try {
      const userList = await request.post(
        `/user/search`,
        { search },
      );

      return userList;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);

export const sendCheckUser = createAsyncThunk(
  `/user/check`,
  async (
    email: string,
    { rejectWithValue },
  ) => {
    try {
      const userId = await request.post(
        `/user/pseudo/create`,
        { email },
      );

      return userId as unknown as Number;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  },
);
