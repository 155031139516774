import React, { useMemo, useState } from "react";
import { debounce } from "lodash";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { Grid, TextField } from "@material-ui/core";
import { useTypedDispatch } from "../../../app/state";
import HorizontalPanel from "../../../components/HorizontalPanel";
import Multiselect from "../../../components/Multiselect";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import { selectCurrentDevice } from "../../../store/deviceStore/deviceSelectors";
import ShortUserModel from "../../../models/ShortUserModel";
import { getWorkflowUserList } from "../../../store/userListStore/userListSelectors";
import UserModel from "../../../models/UserModel";
import SearchUserDialog from "./SearchUserDialog";
import { sendCheckUser } from "../../../store/userStore/userRequests";
import { assignDevice, updateDeviceOperators, updateDeviceOwners } from "../../../store/deviceStore/deviceRequests";

import classes from './index.module.scss';

interface DeviceOwnershipProps {
  formDisabled?: boolean;
  onUpdate: () => any;
}

function getUserList(userList: UserModel[] | ShortUserModel[] | undefined) {
  return (userList || []).map((user) => {
    return {
      id: user.id,
      name: user.profile.fullName,
    }
  });
}

const DeviceOwnership = (props: DeviceOwnershipProps) => {
  const [ isOpen, setOpen ] = useState(false);
  const [ type, setType ] = useState('');
  const dispatch = useTypedDispatch();
  const selectedDevice = useSelector(selectCurrentDevice);
  const currentConfiguration = useSelector(selectCurrentConfiguration) || selectedDevice?.configuration;
  const isHistorySelected = Boolean(currentConfiguration?.parentDeviceConfiguration);
  const managerList = useSelector(getWorkflowUserList) || [];
  const currentOwnership = currentConfiguration?.deviceOwnership;

  const initialValues = {
    requester: currentOwnership?.requester?.profile?.fullName || '',
    lastUpdatedBy: currentOwnership?.lastUpdatedBy?.profile?.fullName || '',
    deviceOwnerList: (currentOwnership?.deviceOwnerList || []).map((user) => user.id),
    deviceOperatorList: (currentOwnership?.deviceOperatorList || []).map((user) => user.id),
    deviceFrequencyManagerList: (currentOwnership?.deviceFrequencyManagerList || []).map((user) => user.id),
  }

  const deviceOwnerList = getUserList(currentOwnership?.deviceOwnerList);
  const deviceOperatorList = getUserList(currentOwnership?.deviceOperatorList);
  const deviceFrequencyManagerList = getUserList(managerList);

  const ownershipFormik = useFormik({
    initialValues,
    onSubmit: () => {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onClick = (type) => () => {
    if (isHistorySelected || props.formDisabled) {
      return;
    }

    setType(type);
    setOpen(true);
  };

  const onUpdateDeviceOwners = async (userIdList: number[]) => {
    if (!selectedDevice) {
      return;
    }

    await dispatch(updateDeviceOwners({
      deviceId: selectedDevice.id,
      userIdList,
    }));

    props.onUpdate();
  }

  const onUpdateDeviceOperators = async (userIdList: number[]) => {
    if (!selectedDevice) {
      return;
    }

    await dispatch(updateDeviceOperators({
      deviceId: selectedDevice.id,
      userIdList,
    }));

    props.onUpdate();
  }

  const onChoose = async (user) => {
    const action = await dispatch(sendCheckUser(user.email));
    const userId = action.payload;

    if (!userId || typeof userId !== 'number' || !isFinite(userId)) {
      return;
    }

    if (type === 'deviceOwnerList') {
      const idList = deviceOwnerList.map((user) => user.id);
      idList.push(userId);
      onUpdateDeviceOwners(idList);
    }

    if (type === 'deviceOperatorList') {
      const idList = deviceOperatorList.map((user) => user.id);
      idList.push(userId);

      onUpdateDeviceOperators(idList);
    }
  }

  const onSaveFM = useMemo(() => {
    return debounce((values) => {
      if (selectedDevice) {
        dispatch(assignDevice({
          deviceId: selectedDevice.id,
          userIdList: values,
        }));
      }
    }, 1000);
  }, [selectedDevice])

  const onUpdateFrequencyManagerList = (values: number[]) => {
    ownershipFormik.setFieldValue('deviceFrequencyManagerList', values);
    onSaveFM(values);
  }

  return (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label='Device requester'
            variant='outlined'
            name='requester'
            fullWidth
            disabled={true}
            value={ownershipFormik.values.requester}
            onChange={ownershipFormik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='Last updated by'
            variant='outlined'
            name='lastUpdatedBy'
            fullWidth
            disabled={true}
            value={ownershipFormik.values.lastUpdatedBy}
            onChange={ownershipFormik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Multiselect
            disabled={isHistorySelected || props.formDisabled}
            title='Owners'
            name='deviceOwnerList'
            fullWidth
            onClick={onClick('deviceOwnerList')}
            items={Multiselect.getValues(deviceOwnerList)}
            selectedItems={Multiselect.getSelectedValues(deviceOwnerList, ownershipFormik.values.deviceOwnerList)}
            onChange={(values: number[]) => onUpdateDeviceOwners(values)}
          />
        </Grid>
        <Grid item xs={12}>
          <Multiselect
            disabled={isHistorySelected || props.formDisabled}
            title='Operators'
            name='deviceOperators'
            fullWidth
            onClick={onClick('deviceOperatorList')}
            items={Multiselect.getValues(deviceOperatorList)}
            selectedItems={Multiselect.getSelectedValues(deviceOperatorList, ownershipFormik.values.deviceOperatorList)}
            onChange={(values: number[]) => onUpdateDeviceOperators(values)}
          />
        </Grid>
        <Grid item xs={12}>
          <Multiselect
            disabled={isHistorySelected || props.formDisabled}
            title='Frequency Managers'
            name='deviceFrequencyManagerList'
            fullWidth
            items={Multiselect.getValues(deviceFrequencyManagerList)}
            selectedItems={Multiselect.getSelectedValues(deviceFrequencyManagerList, ownershipFormik.values.deviceFrequencyManagerList)}
            onChange={onUpdateFrequencyManagerList}
          />
        </Grid>
      </Grid>
      <SearchUserDialog
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onChoose={onChoose}
      />
    </HorizontalPanel>
  );
}

export default DeviceOwnership;