import { Button, Divider, Grid, List, ListItem, ListItemText } from "@material-ui/core";
import BulkEditMenuGeneral from "../BulkEditMenuGeneral";
import classes from "./index.module.scss";

const BulkLeftMenuApprove = ({
  step,
  onNextStep,
  onPreviousStep
}: { step: number, onNextStep: () => void, onPreviousStep: () => void }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          <BulkEditMenuGeneral
            step={0}
            currentStep={step}
            text="Select devices"
          />
          <BulkEditMenuGeneral
            step={1}
            currentStep={step}
            text="Choose operation"
          />
          <BulkEditMenuGeneral
            step={2}
            currentStep={step}
            text="Validation"
            isError={step === 2}
            secondaryText={step > 2 ? "" : "These devices won't be processed because they don't meet the following criteria:"}
            errorMessage={(
              <List className={classes.submenu}>
                <ListItem>
                  <ListItemText classes={{ primary: classes.submenuText }}>
                    Logged user must be in Frequency Manager list of a device
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText classes={{ primary: classes.submenuText }}>
                    Devices must be submitted
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText classes={{ primary: classes.submenuText }}>
                    Workflow with the current user as a Frequency Manager should exist
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText classes={{ primary: classes.submenuText }}>
                    Devices must be pinned to the map
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText classes={{ primary: classes.submenuText }}>
                    Devices must have at least one wireless interface and at least one channel per interface
                  </ListItemText>
                </ListItem>
              </List>
            )}
          />
          <BulkEditMenuGeneral
            step={3}
            currentStep={step}
            text="Operation Details"
          />
          <BulkEditMenuGeneral
            step={4}
            currentStep={step}
            text="Confirmation"
          />
        </List>
      </Grid>
      <Divider className={classes.divider} />
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={6}>
          <Button color='primary' variant='outlined' disabled={step === 0} fullWidth onClick={onPreviousStep}>
            Previous step
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button color='primary' variant='contained' fullWidth onClick={onNextStep}>
            {
              step === 4 ? 'Approve' : 'Next step'
            }
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BulkLeftMenuApprove;
