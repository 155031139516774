export enum PAGES {
  HOME = '/',
  LOGIN_PAGE = '/login',
  TRIAL_EXPIRED = '/trial-expired',
  SSO_LOGIN = '/sso',
  SSO_LOGIN_SUCCESS = '/login-success',
  USER_CREATE_PASSWORD_PAGE = '/password/create',
  SETTINGS_PAGE = '/settings',
  REGIONS_AND_COUNTRY = '/settings/region-and-country',
  USER_MANAGEMENT = '/settings/users',
  WORKFLOW_MANAGEMENT = '/settings/workflows',
  IMPORT = '/settings/import',
  GLOBAL_SETTINGS = '/settings/global',
  USER_MANAGEMENT_ALL = '/settings/users/all',
  USER_MANAGEMENT_NEW = '/settings/users/new',
  USER_MANAGEMENT_ACTIVATION = '/settings/users/activation',
  USER_MANAGEMENT_ACTIVE = '/settings/users/active',
  USER_MANAGEMENT_INACTIVE = '/settings/users/inactive',
  DEVICES_MAP = '/locations/buildings/floors/devices/map',
  TECHNOLOGIES = '/settings/technologies',
  WIRELESS_SYSTEM = '/settings/wireless-system',
  RESPONSIBLE_UNITS = '/settings/units',
  REQUESTS = '/requests',
  LOCATIONS_PAGE = '/locations',
  BUILDINGS_PAGE = '/locations/buildings',
  UPLOAD_MAP = '/locations/buildings/upload-map',
  MAP_PAGE = '/map',
  FREQUENCY_ANALYSIS = '/locations/buildings/floors/frequency-analysis',
  DEVICE_MAP = '/map/device',
  SINGLE_DEVICE_PAGE = '/device',
  DEVICE_TEMPLATE_PAGE = '/template',
  SEARCH_PAGE = '/search',
  DEVICES_BULK_EDIT = '/bulk-edit'
}