import { useSelector } from "react-redux";
import { selectSearchDeviceList } from "../../../store/deviceStore/deviceSelectors";
import DeviceTable from "../../../components/DeviceTable";
import { DeviceColumnList } from "../../../components/DeviceTable/devicesColumns";

const SearchTable = ({ columnList }: { columnList: DeviceColumnList }) => {
  const deviceList = useSelector(selectSearchDeviceList);

  return (
    <DeviceTable deviceList={deviceList} columnList={columnList} />
  )
}

export default SearchTable;