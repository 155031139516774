import { useSelector } from "react-redux";
import { useTypedDispatch } from "../../../app/state";
import { useSnackbar } from "../../../app/shared/snackbar-context/snackbar.context";
import { useShowModal } from "../../../app/shared/modal-context/modal.context";
import {
  selectCurrentResponsibleUnit,
  selectCurrentResponsibleUnitsId,
} from "../../../store/responsibleUnitStore/responsibleUnitsSelectors";
import { IconButton, TextField } from "@material-ui/core";
import { useFormik } from "formik";
import SaveIcon from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/Delete";
import {
  createResponsibleUnit, deleteResponsibleUnit,
  updateResponsibleUnit
} from "../../../store/responsibleUnitStore/responsibleUnitsRequests";
import ResponsibleUnitModel from "../../../models/ResponsibleUnitModel";
import HorizontalPanel from "../../../components/HorizontalPanel";
import { Toolbar } from "../../../app/components";
import { ModalType } from "../../../app/shared/modal-context/constants";
import { setCurrentResponsibleUnit } from "../../../store/responsibleUnitStore/responsibleUnitsState";

import classes from "./index.module.scss";

const ResponsibleUnitEdit = () => {
  const dispatch = useTypedDispatch();
  const { openSnackbar } = useSnackbar();
  const { showModal } = useShowModal();
  const currentResponsibleUnitId = useSelector(selectCurrentResponsibleUnitsId);
  const currentUnit = useSelector(selectCurrentResponsibleUnit);

  const isCreateMode = currentResponsibleUnitId === -1;
  const title = isCreateMode ? 'Create responsible unit' : 'Edit responsible unit';

  const isDeleteDisabled = currentResponsibleUnitId === -1;

  const unitFormik = useFormik({
    initialValues: {
      name: currentUnit?.name || '',
    },
    onSubmit: () => {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const onSave = () => {
    if (!unitFormik.values.name) {
      return;
    }

    if (isCreateMode) {
      dispatch(
        createResponsibleUnit({
          name: unitFormik.values.name,
        })
      ).then((action) => {
        const data = action.payload as ResponsibleUnitModel;
        if (!data) {
          openSnackbar({
            title: "Cannot create responsible unit",
            message: "There was an error creating responsible unit",
            severity: "error",
          })
        } else {
          dispatch(setCurrentResponsibleUnit(data.id));
        }
      })

      return;
    }

    if (currentResponsibleUnitId) {
      dispatch(updateResponsibleUnit({
        id: currentResponsibleUnitId,
        name: unitFormik.values.name,
      })).then((action) => {
        const data = action.payload as ResponsibleUnitModel;
        if (!data) {
          openSnackbar({
            title: "Cannot update responsible unit",
            message: "There is already one unit with the same name",
            severity: "error",
          });
        }
      })
    }
  }

  const onDelete = () => {
    if (currentResponsibleUnitId) {
      dispatch(deleteResponsibleUnit(currentResponsibleUnitId))
        .then((action) => {
          const payload = action.payload as number;

          if (payload) {
            dispatch(setCurrentResponsibleUnit(null));
          } else {
            openSnackbar({
              title: "Cannot delete responsible unit",
              message: "This responsible unit is already in use",
              severity: "error",
            });
          }
        })
    }
  }

  const onDeleteHandler = () => {
    showModal(ModalType.MODAL_GENERIC_CONFIRM, {
      title: 'Delete responsible unit',
      text: `Are you sure you want to delete this responsible unit?`,
      confirmButtonHandler: onDelete,
      confirmButtonText: 'Delete',
    });
  }

  return currentResponsibleUnitId ? (
    <HorizontalPanel size={HorizontalPanel.SIZES.WHOLE_SPACE}>
      <HorizontalPanel size={HorizontalPanel.SIZES.SMALL}>
        <Toolbar title={title}>
          <IconButton
            disabled={!unitFormik.dirty}
            aria-label='save changes'
            color='secondary'
            size='small'
            onClick={onSave}
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            disabled={isDeleteDisabled}
            aria-label='delete'
            color='secondary'
            size='small'
            onClick={onDeleteHandler}
          >
            <Delete />
          </IconButton>
        </Toolbar>
      </HorizontalPanel>
      <HorizontalPanel size={HorizontalPanel.SIZES.REST_SPACE} className={classes.form}>
        <TextField
          fullWidth
          label="Responsible unit name"
          variant="outlined"
          name="name"
          required
          value={unitFormik.values.name}
          onChange={unitFormik.handleChange}
          helperText={unitFormik.errors.name}
          error={Boolean(unitFormik.errors.name)}
        />
      </HorizontalPanel>
    </HorizontalPanel>
  ) : null;
}

export default ResponsibleUnitEdit;