import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../app/api";
import { serializeError } from "../../app/shared";
import ResponsibleUnitModel from "../../models/ResponsibleUnitModel";

const SLICE_NAME = 'responsible-units';

export const getAllResponsibleUnits = createAsyncThunk(
  `${SLICE_NAME}/all`,
  async (_, { rejectWithValue }) => {
    try {
      const { units }: { units: ResponsibleUnitModel[] } = await request.get("/units/all");
      return units;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const createResponsibleUnit = createAsyncThunk(
  `${SLICE_NAME}/create`,
  async (body: { name: string }, { rejectWithValue }) => {
    try {
      const { unit }: { unit: ResponsibleUnitModel } = await request.post("/units", body);
      return unit;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const updateResponsibleUnit = createAsyncThunk(
  `${SLICE_NAME}/update`,
  async (body: { id: number, name: string }, { rejectWithValue }) => {
    try {
      const { unit }: { unit: ResponsibleUnitModel } = await request.put("/units", body);
      return unit;
    } catch (error) {
      return rejectWithValue(serializeError(error));
    }
  }
);

export const deleteResponsibleUnit = createAsyncThunk(
  `${SLICE_NAME}/delete`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { success }: { success: true } = await request.delete(`/units/${id}`);
      return success ? id : 0;
    } catch (error) {
      return 0;
    }
  }
);