import { combineReducers } from '@reduxjs/toolkit';
import { userSlice } from '../../store/userStore/userState';
import { workflowSlice } from '../../store/workflows/workflowState';
import { userListSlice } from '../../store/userListStore/userListState';
import { userActionSlice } from '../../store/userActionStore/userActionState';
import { importSlice } from "../../store/import/importState";
import { freqAnalysisSlice } from '../../store/freq-analysis/slice';
import { settingsSlice } from "../../store/settingsStore/settingsState";
import { buildingSlice } from "../../store/buildingStore/buildingsState";
import { locationSlice } from "../../store/locationStore/locationState";
import { floorSlice } from "../../store/floorStore/floorState";
import { regionSlice } from "../../store/regionStore/regionState";
import { countrySlice } from "../../store/countryStore/countryState";
import { wirelessSystemSlice } from "../../store/wirelessSystemStore/wirelessSystemState";
import { deviceSlice } from "../../store/deviceStore/deviceState";
import { deviceConfigurationSlice } from "../../store/deviceConfigurationStore/deviceConfigurationState";
import { technologyConfigurationSlice } from "../../store/technologyConfigurationStore/technologyConfigurationState";
import { mapPointsState } from "../../store/mapPointsStore/mapPointsState";
import { generalSlice } from "../../store/generalStore/generalState";
import { mapSlice } from "../../store/mapStore/mapState";
import { filterSlice } from "../../store/filterStore/filterState";
import { deviceTemplateSlice } from "../../store/templatesStore/templateState";
import { drawingSlice } from "../../store/drawingStore/drawingState";
import { keyboardSlice } from "../../store/keyboardStore/keyboardState";
import { deviceTagSlice } from "../../store/deviceTagsStore/deviceTagsState";
import { responsibleUnitsSlice } from "../../store/responsibleUnitStore/responsibleUnitsState";

export const rootReducer = combineReducers({
  [userSlice.name]: userSlice.reducer,
  [userListSlice.name]: userListSlice.reducer,
  [userActionSlice.name]: userActionSlice.reducer,
  [workflowSlice.name]: workflowSlice.reducer,
  [importSlice.name]: importSlice.reducer,
  [freqAnalysisSlice.name]: freqAnalysisSlice.reducer,
  [settingsSlice.name]: settingsSlice.reducer,

  [buildingSlice.name]: buildingSlice.reducer,
  [locationSlice.name]: locationSlice.reducer,
  [floorSlice.name]: floorSlice.reducer,
  [regionSlice.name]: regionSlice.reducer,
  [countrySlice.name]: countrySlice.reducer,
  [wirelessSystemSlice.name]: wirelessSystemSlice.reducer,
  [deviceSlice.name]: deviceSlice.reducer,
  [deviceTagSlice.name]: deviceTagSlice.reducer,
  [deviceConfigurationSlice.name]: deviceConfigurationSlice.reducer,
  [technologyConfigurationSlice.name]: technologyConfigurationSlice.reducer,
  [mapPointsState.name]: mapPointsState.reducer,
  [mapSlice.name]: mapSlice.reducer,
  [deviceTemplateSlice.name]: deviceTemplateSlice.reducer,

  [generalSlice.name]: generalSlice.reducer,
  [filterSlice.name]: filterSlice.reducer,
  [drawingSlice.name]: drawingSlice.reducer,
  [keyboardSlice.name]: keyboardSlice.reducer,
  [responsibleUnitsSlice.name]: responsibleUnitsSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
