import { requestAllDevicesByQuery } from "../../store/deviceStore/deviceRequests";
import { GeneralRequest } from "../../requests/GeneralRequest";
import { getQueryObject } from "../index";
import { getAllWirelessSystems } from "../../store/wirelessSystemStore/wirelessSystemRequests";
import { requestAllCountries } from "../../store/countryStore/countryRequests";
import { requestLocationByQuery } from "../../store/locationStore/locationRequests";
import {
  requestAllTechnologyConfigurations,
} from "../../store/technologyConfigurationStore/technologyConfigurationRequests";
import { getAllFloors } from "../../store/floorStore/floorRequests";
import BulkEditSteps from "../../containers/BulkEditPage/BulkEditSteps";

const BulkDevicesPage = () => {
  const searchObject = getQueryObject();
  const { deviceIdList } = searchObject;

  return (
    <GeneralRequest
      requestAction={requestAllDevicesByQuery}
      requestArguments={[ { deviceIdList: deviceIdList || [] } ]}
    >
      <GeneralRequest requestAction={getAllWirelessSystems} loadingElement={null}>
        <GeneralRequest requestAction={requestAllCountries} loadingElement={null}>
          <GeneralRequest requestAction={requestLocationByQuery} loadingElement={null}>
            <GeneralRequest
              requestAction={requestAllTechnologyConfigurations}
              loadingElement={null}
            >
              <GeneralRequest requestAction={getAllFloors}>
                <BulkEditSteps />
              </GeneralRequest>
            </GeneralRequest>
          </GeneralRequest>
        </GeneralRequest>
      </GeneralRequest>
    </GeneralRequest>
  )
}

export default BulkDevicesPage;