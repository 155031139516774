import { createSelector } from '@reduxjs/toolkit';
import { get } from 'lodash/fp';
import { RootState } from 'app/state';
import { userAdapter, userListSlice } from './userListState';
import { USER_STATUS } from './userStatus';
import { UserModel } from '../../models/UserModel';

export const selectUserListState = (state: RootState) => state[userListSlice.name];

export const selectUserListAdapter = createSelector(
  selectUserListState,
  (state) => state.userList
);

const userListAdapterSelectors = userAdapter.getSelectors(
  selectUserListAdapter
);

export const selectAllUserList = userListAdapterSelectors.selectAll;

export const selectUserList = createSelector(
  selectAllUserList,
  (allUsers) => {
    return allUsers.filter((user) => {
      const superAdmin = (user.userRoles || []).find((role) => role.type === 'Super Admin');

      return !superAdmin;
    })
  },
);

export const selectRolesList = createSelector(
  selectUserListState,
  get('rolesList'),
);

export const selectRoleListTypes = createSelector(
  selectRolesList,
  (roleList) => (roleList || []).map((role) => {
    return {
      id: role.id,
      value: role.type,
    }
  })
);

export const selectNewUsers = createSelector(
  selectUserList,
  (userList) => (userList || []).filter((user: UserModel) => user.status === USER_STATUS.NEW),
);

export const selectActivationUsers = createSelector(
  selectUserList,
  (userList) => (userList || []).filter((user: UserModel) => user.status === USER_STATUS.ACTIVATION),
);

export const selectActiveUsers = createSelector(
  selectUserList,
  (userList) => (userList || []).filter((user: UserModel) => user.status === USER_STATUS.ACTIVE),
);

export const selectInactiveUsers = createSelector(
  selectUserList,
  (userList) => (userList || []).filter((user: UserModel) => user.status === USER_STATUS.INACTIVE),
);

export const selectUserListStatus = createSelector(
  selectUserListState,
  get('userListStatus'),
);

export const selectRolesListStatus = createSelector(
  selectUserListState,
  get('rolesStatus'),
);

export const getFrequencyManagersList = createSelector(
  selectUserListState,
  get('managersList'),
);

export const getSearchRequestersList = createSelector(
  selectUserListState,
  (state) => state.searchUserList.requesterList
);

export const getSearchOwnerList = createSelector(
  selectUserListState,
  (state) => state.searchUserList.ownerList
);

export const getSearchManagerList = createSelector(
  selectUserListState,
  (state) => state.searchUserList.managerList
);

export const getSearchOperatorList = createSelector(
  selectUserListState,
  (state) => state.searchUserList.operatorList
);

export const getFrequencyManagersStatus = createSelector(
  selectUserListState,
  get('managersStatus'),
);

export const selectRequesterListStatus = createSelector(
  selectUserListState,
  get('requesterListStatus'),
);

export const getWorkflowListStatus = createSelector(
  selectUserListState,
  get('workflowListStatus'),
);

export const getWorkflowUserList = createSelector(
  selectUserListState,
  get('workflowList'),
);