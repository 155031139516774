import { useSelector } from "react-redux";
import DeviceModel from "../../models/DeviceModel";
import { selectAllWirelessSystems } from "../../store/wirelessSystemStore/wirelessSystemSelectors";
import { selectAllCountries } from "../../store/countryStore/countrySelectors";
import { selectAllLocations } from "../../store/locationStore/locationSelectors";
import { selectAllBuildings } from "../../store/buildingStore/buildingsSelectors";
import { selectAllFloors } from "../../store/floorStore/floorSelectors";
import { pagePathGenerator } from "../../routes/pagePathGenerator";
import DeviceConfigurationModel from "../../models/DeviceConfigurationModel";
import LocationModel from "../../models/LocationModel";
import FloorModel from "../../models/FloorModel";
import StyledDataGrid from "../StyledDataGrid";
import createDeviceColumns, { DeviceColumnList } from "./devicesColumns";

interface DeviceTableProps {
  showCheckboxes?: boolean,
  showDeviceLink?: boolean,
  deviceList: DeviceModel[],
  onSelect?: (selectionModel: number[]) => void,
  selectedDeviceIdList?: number[],
  columnList?: DeviceColumnList
}

const DeviceTable = (props: DeviceTableProps) => {
  const { onSelect = (_) => {}, selectedDeviceIdList = [], showDeviceLink = true, columnList = {} } = props;
  const wirelessSystemList = useSelector(selectAllWirelessSystems);
  const countryList = useSelector(selectAllCountries);
  const locationList = useSelector(selectAllLocations);
  const buildingList = useSelector(selectAllBuildings);
  const floorList = useSelector(selectAllFloors);

  const getDeviceLink = (device?: DeviceModel | null) => {
    return device ? pagePathGenerator.SINGLE_DEVICE_PAGE({
      mapId: device.mapId,
      deviceId: device.id,
    }) : '';
  }

  const findWirelessSystem = (wirelessSystemId?: number | null) => {
    return wirelessSystemId ? wirelessSystemList.find((wirelessSystem) => wirelessSystem.id === wirelessSystemId)?.name || '' : '';
  }

  const findRequester = (configuration?: DeviceConfigurationModel | null) => {
    return configuration?.deviceOwnership?.requester?.profile?.fullName || '';
  }

  const findOwner = (configuration?: DeviceConfigurationModel | null) => {
    return (configuration?.deviceOwnership?.deviceOwnerList || []).map((user) => user.profile.fullName).join(', ') || 'Unassigned';
  }

  const findOperator = (configuration?: DeviceConfigurationModel | null) => {
    return (configuration?.deviceOwnership?.deviceOperatorList || []).map((user) => user.profile.fullName).join(', ') || 'Unassigned';
  }

  const findManager = (configuration?: DeviceConfigurationModel | null) => {
    return (configuration?.deviceOwnership?.deviceFrequencyManagerList || []).map((user) => user.profile.fullName).join(', ') || 'Unassigned';
  }

  const getWirelessInterfaceList = (configuration?: DeviceConfigurationModel | null) => {
    const list = configuration?.wirelessInterfaces || [];
    const names = list.map((wirelessInterface) => wirelessInterface.technologyConfiguration?.name);

    return names.join(' / ');
  }

  const getOriginForLocation = (location?: LocationModel) => {
    if (!location) {
      return '';
    }

    const country = countryList.find((country) => country.id === location.countryId);

    return [ country?.name, location.name ].join(' / ');
  }

  const getOriginForFloor = (floor: FloorModel) => {
    const building = buildingList.find((building) => building.id === floor.buildingId);
    const location = locationList.find((location) => location.id === building?.locationId);

    return [ getOriginForLocation(location), building?.name, floor?.name ].join(' / ');
  }

  const getDeviceOrigin = (device?: DeviceModel | null) => {
    const mapId = device?.mapId;

    const foundFloor = floorList.find((floor) => floor.mapId === mapId);
    const foundLocation = locationList.find((location) => location.mapId === mapId);

    if (foundFloor) {
      return getOriginForFloor(foundFloor);
    }

    if (foundLocation) {
      return getOriginForLocation(foundLocation);
    }

    return '';
  }

  const deviceListRows = props.deviceList.map((device) => {
    return {
      id: device.id,
      device: device,
      name: { link: getDeviceLink(device), text: device.name, showDeviceLink },
      origin: getDeviceOrigin(device),
      wirelessSystem: findWirelessSystem(device.configuration?.wirelessSystemId),
      requester: findRequester(device.configuration),
      owner: findOwner(device.configuration),
      operator: findOperator(device.configuration),
      manager: findManager(device.configuration),
      wirelessInterfaceList: getWirelessInterfaceList(device.configuration),
      updatedAt: device.configuration?.deviceOwnership?.updatedOn,
    }
  });

  const tableColumns = createDeviceColumns(columnList);

  return (
    <StyledDataGrid
      checkboxSelection={props.showCheckboxes || false}
      autoWidth
      columns={tableColumns}
      disableColumnSelector
      rows={deviceListRows}
      onSelectionModelChange={onSelect}
      selectionModel={props.showCheckboxes ? selectedDeviceIdList : []}
      density="compact"
    />
  )
}

export default DeviceTable;