import React, { useState } from 'react';
import { get } from 'lodash';
import cn from 'classnames';
import {
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Chip,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import styles from './index.module.scss';
import Tooltip from "@material-ui/core/Tooltip";

type Item = { id: number | string, value: string };

type MultiselectProps = {
  title?: string;
  name: string;
  items?: Item[];
  classes?: {
    wrapper?: string;
    select?: string;
  };
  helperText?: React.ReactNode,
  error?: boolean,
  selectedItems?: Item[];
  onChange: (values?: (number | string)[]) => any;
  onClick?: () => any;
  onDelete?: (value: Item) => any;
  disabled?: boolean;
  fullWidth?: boolean;
  maxChipLength?: number;
  required?: boolean;
};

const Multiselect = ({
  title,
  name,
  items = [],
  onChange,
  selectedItems = [],
  classes,
  onDelete,
  helperText = null,
  error,
  disabled,
  fullWidth,
  onClick,
  required = false,
  maxChipLength,
}: MultiselectProps) => {
  const [ isOpened, setIsOpened ] = useState(false);

  const handleDelete = (event: React.SyntheticEvent, value: Item) => {
    event.preventDefault();
    event.stopPropagation();
    if (disabled) {
      return;
    }
    if (onDelete) {
      onDelete(value);
    } else {
      const newValues = selectedItems.filter((item) => item.id !== value.id).map((item) => item.id);
      onChange(newValues);
    }
  };

  const noop = () => {};

  const onOpen = () => {
    if (!onClick) {
      setIsOpened(true);
    }
  }

  const onClose = () => {
    setIsOpened(false);
  }

  return (
    <FormControl
      className={cn(classes?.wrapper, styles.wrapper)}
      variant='outlined'
      error={error}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick || noop}
      required={required}
    >
      <InputLabel id={name} className={styles.label}>
        {title}
      </InputLabel>
      <Select
        variant='outlined'
        labelId={name}
        name={name}
        disabled={disabled}
        multiple
        fullWidth
        open={isOpened}
        onOpen={onOpen}
        onClose={onClose}
        value={selectedItems.map((item) => item?.id)}
        onChange={(e: React.ChangeEvent<{ value: number[] }>) => onChange(e.target.value)}
        className={styles.select}
        classes={{
          select: styles.selectContent,
        }}
        renderValue={(selected) => (
          <Grid container>
            {(selected as number[]).map((id) => {
                const currentItem = items.find((i) => i.id === id) || items[0];
                if (maxChipLength && (currentItem?.value || '').length > maxChipLength) {
                  return (
                    <Tooltip
                      title={currentItem?.value}
                      key={currentItem?.id}
                    >
                      <Chip
                        label={(currentItem?.value || '').slice(0, maxChipLength - 3) + '...'}
                        className={styles.chip}
                        onDelete={() => {}}
                        // https://github.com/mui-org/material-ui/issues/18658 select is opened on delete
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => handleDelete(event, currentItem)}
                          />
                        }
                      />
                    </Tooltip>
                  )
                }
                return (
                  <Chip
                    key={currentItem?.id}
                    label={currentItem?.value}
                    className={styles.chip}
                    onDelete={() => {}}
                    // https://github.com/mui-org/material-ui/issues/18658 select is opened on delete
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => handleDelete(event, currentItem)}
                      />
                    }
                  />
                )
              }
            )}
          </Grid>
        )}
      >
        {items.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

type AnyModel = {
  [key: string]: any,
}

Multiselect.getValues = (props: AnyModel[], idKey = 'id', valueKey = 'name'): Item[] => {
  return (props || []).map((model) => {
    return {
      id: get(model, idKey),
      value: get(model, valueKey),
    }
  })
}

Multiselect.getSelectedValues = (modelList: AnyModel[], selectedIndex: number[], idKey = 'id', valueKey = 'name'): Item[] => {
  return selectedIndex.map((index) => {
    const foundModel = modelList.find((model) => model[idKey] === index);
    if (!foundModel) {
      return {
        id: -1,
        value: 'ERROR',
      }
    }
    return {
      id: foundModel[idKey],
      value: foundModel[valueKey],
    }
  });
}

export default Multiselect;
