import React, { useMemo, useState } from "react";
import { debounce } from "lodash";
import { Grid, InputAdornment, OutlinedInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { GenericModal } from "../../../../app/components/ui/generic-modal/GenericModal";
import MenuList from "../../../../components/MenuList";
import { useTypedDispatch } from "../../../../app/state";
import { searchUser } from "../../../../store/userStore/userRequests";

import classes from './index.module.scss';

type SearchedUser = {
  username: string;
  email: string;
  unit: string;
  fullName: string;
}

interface SearchUserDialogProps {
  isOpen: boolean,
  onClose: () => any,
  onChoose: (user: SearchedUser) => any,
}

const SearchUserDialog = (props: SearchUserDialogProps) => {
  const dispatch = useTypedDispatch();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ userList, setUserList ] = useState([] as SearchedUser[]);
  const [ selectedUser, setSelectedUser ] = useState(-1);

  const onClear = () => {
    setSearch('');
    setUserList([]);
    setSelectedUser(-1);
    setIsLoading(false);
  }

  const doSearch = (searchText: string) => {
    const trimmed = searchText.trim();
    if (trimmed.length >= 2) {
      dispatch(searchUser(trimmed))
        .then((action) => {
          const userList = action.payload as SearchedUser[]
          setUserList(userList);
          setTimeout(() => {
            setIsLoading(false);
          }, 15)
        });
    }
  }

  const onClose = () => {
    props.onClose();
    onClear();
  }

  const searchDebounced = useMemo(() => {
    return debounce(doSearch, 2500);
  }, [])

  const onChange = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (value.length < 2) {
      setIsLoading(false);
      setUserList([]);
      setSelectedUser(-1);
    } else {
      setIsLoading(true);
    }

    searchDebounced(value);
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 27) {
      onClose();
    }
  }

  const onSelect = () => {
    const user = userList[selectedUser];
    props.onChoose(user);
    onClear();
  };

  let searchResultText = '';

  if (isLoading) {
    searchResultText = 'Searching...';
  } else if (search.length < 2) {
    searchResultText = 'Type at least 2 symbols';
  } else if (userList.length > 20) {
    searchResultText = 'Too many results';
  } else {
    searchResultText = `${userList.length} users found`;
  }

  return (
    <GenericModal
      isOpen={props.isOpen}
      closeModal={onClose}
      title="Select user"
      isConfirmButtonDisabled={selectedUser === -1}
      confirmButtonText={'Add user'}
      confirmButtonHandler={onSelect}
      declineButtonText={'Cancel'}
      declineButtonHandler={onClose}
      actionText={searchResultText}
      fixedHeight
    >
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.search}
        >
          <OutlinedInput
            fullWidth
            value={search}
            onChange={onChange}
            onKeyDown={onKeyDown}
            startAdornment={(
              <InputAdornment position={"start"}>
                <SearchIcon />
              </InputAdornment>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {
            userList.length > 0 ? (
              <MenuList
                disableSearch
                selectItem={(value) => setSelectedUser(value)}
                items={userList.map((user, id) => {
                  return {
                    id,
                    name: user.fullName,
                    secondary: [ user.unit, user.username, user.email ].filter(Boolean).join(' '),
                  }
                })}
                size='big'
                selectedItemId={selectedUser}
              />
            ) : null
          }
        </Grid>
      </Grid>
    </GenericModal>
  )
}

export default SearchUserDialog;