import Language from '@material-ui/icons/Language';
import GroupIcon from '@material-ui/icons/Group';
import WifiTetheringRoundedIcon from '@material-ui/icons/WifiTetheringRounded';
import WifiIcon from '@material-ui/icons/Wifi';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import BuildIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import { ListItemIcon } from '@material-ui/core';
import PAGES from '../../../pages';
import NavigationMenu from '../../Shared/NavigationMenu';
import UserManagementFilters from '../UserManagementFilters';
import classes from './index.module.scss';
import { PERMISSIONS } from "../../../store/userStore/permissions.enum";

// TODO: add translations
const settingsPageArray = [
  {
    title: 'Regions & Country',
    route: PAGES.REGIONS_AND_COUNTRY,
    permissionName: PERMISSIONS.REGIONS_AND_COUNTRY_ACTIONS,
    icon: (
      <ListItemIcon>
        <Language />
      </ListItemIcon>
    ),
  },
  {
    title: 'User Management',
    route: PAGES.USER_MANAGEMENT,
    permissionName: PERMISSIONS.USER_ACTIONS,
    icon: (
      <ListItemIcon>
        <GroupIcon />
      </ListItemIcon>
    ),
    nestedMenu: <UserManagementFilters />,
  },
  {
    title: 'Technologies',
    route: PAGES.TECHNOLOGIES,
    permissionName: PERMISSIONS.TECHNOLOGY_CONFIGURATION_ACCESS,
    icon: (
      <ListItemIcon>
        <WifiTetheringRoundedIcon />
      </ListItemIcon>
    ),
  },
  {
    title: 'Wireless systems',
    route: PAGES.WIRELESS_SYSTEM,
    permissionName: PERMISSIONS.WIRELESS_SYSTEM_ACTIONS,
    icon: (
      <ListItemIcon>
        <WifiIcon />
      </ListItemIcon>
    ),
  },
  {
    title: 'Responsible Units',
    route: PAGES.RESPONSIBLE_UNITS,
    permissionName: PERMISSIONS.RESPONSIBLE_UNIT_CHANGE,
    icon: (
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
    ),
  },
  {
    title: 'Workflows',
    route: PAGES.WORKFLOW_MANAGEMENT,
    permissionName: PERMISSIONS.WORKFLOW_ACTIONS,
    icon: (
      <ListItemIcon>
        <SpeakerNotesIcon />
      </ListItemIcon>
    ),
  },
  {
    title: 'Import',
    route: PAGES.IMPORT,
    permissionName: PERMISSIONS.IMPORT_ACCESS,
    icon: (
      <ListItemIcon>
        <ImportExportIcon />
      </ListItemIcon>
    ),
  },
  {
    title: 'Application Settings',
    route: PAGES.GLOBAL_SETTINGS,
    permissionName: PERMISSIONS.GLOBAL_SETTINGS_ACTIONS,
    icon: (
      <ListItemIcon>
        <BuildIcon />
      </ListItemIcon>
    ),
  },
];

const SettingsNavigation = () => (
  <NavigationMenu pagesArray={settingsPageArray} menuClasses={classes} />
);

export default SettingsNavigation;
