import { RootState } from "../../app/state";
import { responsibleUnitsAdapter } from "./responsibleUnitsState";
import { createSelector } from "@reduxjs/toolkit";

const selectRoot = (state: RootState) => state;
export const selectResponsibleUnitsSlice = (state: RootState) => state.responsibleUnits;

const responsibleUnitsSelectors = responsibleUnitsAdapter.getSelectors(selectResponsibleUnitsSlice);

export const selectAllResponsibleUnits = responsibleUnitsSelectors.selectAll;
export const selectResponsibleUnitById = responsibleUnitsSelectors.selectById;

export const selectCurrentResponsibleUnitsId = createSelector(
  selectResponsibleUnitsSlice,
  (state) => state.selectedResponsibleUnitId
);

export const selectCurrentResponsibleUnit = createSelector(
  selectRoot,
  selectCurrentResponsibleUnitsId,
  (root, id) => (id && selectResponsibleUnitById(root, id)) || null
)