import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ChildrenElement from "../../../../models/HelperModels/ChildrenElement";

import classes from './index.module.scss';

export type ModalProps = {
  isOpen: boolean;
  text?: string;
  title?: string;
  fixedHeight?: boolean;
  closeModal: () => void;
  confirmButtonHandler?: () => void;
  closeOnConfirm?: boolean;
  declineButtonHandler?: () => void;
  confirmButtonText?: string;
  actionText?: string;
  declineButtonText?: string;
  isConfirmButtonDisabled?: boolean;
  children?: ChildrenElement;
};

export const GenericModal = ({
  isOpen,
  closeModal,
  text,
  title,
  children,
  confirmButtonHandler,
  closeOnConfirm = true,
  declineButtonHandler,
  confirmButtonText,
  actionText,
  declineButtonText,
  fixedHeight = false,
  isConfirmButtonDisabled = false,
}: ModalProps) => {
  const onConfirmButtonHandler = () => {
    if (closeOnConfirm) {
      closeModal();
    }
    if (!confirmButtonHandler) {
      return;
    }
    confirmButtonHandler();
  }

  const onDeleteButtonHandler = () => {
    closeModal();
    if (!declineButtonHandler) {
      return;
    }
    declineButtonHandler();
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container className={classes.title}>
          <Grid item xs={11}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={closeModal} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        className={fixedHeight ? classes.fixedHeight : ''}
      >
        {
          children ?
            children : (
              <DialogContentText
                id="alert-dialog-description"
                className={classes.contentText}
              >
                {text}
              </DialogContentText>
            )
        }
      </DialogContent>
      <DialogActions className={classes.footer}>
        {actionText && (
          <Grid container justifyContent="flex-start" alignItems="center">
            <Typography variant="subtitle1">{actionText}</Typography>
          </Grid>
        )}
        <Grid container justifyContent="flex-end">
          {confirmButtonText && (
            <Grid item>
              <Button
                onClick={onConfirmButtonHandler}
                color="secondary"
                disabled={isConfirmButtonDisabled}
              >
                {confirmButtonText}
              </Button>
            </Grid>
          )}
          {declineButtonText && (
            <Grid item>
              <Button onClick={onDeleteButtonHandler} color="primary" autoFocus>
                {declineButtonText}
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
