import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import ResponsibleUnitModel from "../../models/ResponsibleUnitModel";
import { LoadingStatus, status } from "../../app/shared";
import {
  createResponsibleUnit,
  deleteResponsibleUnit,
  getAllResponsibleUnits,
  updateResponsibleUnit
} from "./responsibleUnitsRequests";

export const responsibleUnitsAdapter = createEntityAdapter<ResponsibleUnitModel>({
  selectId: (unit) => unit.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
})

type ResponsibleUnitsState = {
  selectedResponsibleUnitId?: number | null;
  loadAllStatus: LoadingStatus,
} & EntityState<ResponsibleUnitModel>

const initialState: ResponsibleUnitsState = {
  selectedResponsibleUnitId: null,
  loadAllStatus: status.default,
  ...responsibleUnitsAdapter.getInitialState(),
}

export const responsibleUnitsSlice = createSlice({
  name: 'responsibleUnits',
  initialState,
  reducers: {
    setCurrentResponsibleUnit: (state, action) => {
      state.selectedResponsibleUnitId = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllResponsibleUnits.fulfilled, (state, action) => {
        responsibleUnitsAdapter.setAll(state, action.payload);
      })
      .addCase(createResponsibleUnit.fulfilled, (state, action) => {
        responsibleUnitsAdapter.upsertOne(state, action.payload);
      })
      .addCase(updateResponsibleUnit.fulfilled, (state, action) => {
        responsibleUnitsAdapter.upsertOne(state, action.payload);
      })
      .addCase(deleteResponsibleUnit.fulfilled, (state, action) => {
        if (action.payload) {
          responsibleUnitsAdapter.removeOne(state, action.payload);
        }
      }),
});

export const { setCurrentResponsibleUnit } = responsibleUnitsSlice.actions;