import React from 'react';
import { useSelector } from 'react-redux';
import {
  useTypedDispatch,
} from 'app/state';
import DeviceIcon from 'components/DeviceIcon/DeviceIcon';
import MenuListWithImage from "../MenuListWithImage";
import { selectCurrentDevice } from "../../store/deviceStore/deviceSelectors";
import {
  selectAllConfigurations,
  selectCurrentConfigurationId
} from "../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import { setCurrentConfigurationId } from "../../store/deviceConfigurationStore/deviceConfigurationState";
import { setCurrentWirelessInterfaceId } from 'store/deviceStore/deviceState';
import { dateFormatted } from "../../helpers/timeHelpers";
import DeviceTag from "../DeviceTag";

export default function DeviceHistory() {
  const dispatch = useTypedDispatch();
  const currentDevice = useSelector(selectCurrentDevice);
  const currentDeviceConfiguration = currentDevice?.configuration;
  const currentHistory = useSelector(selectAllConfigurations);
  const currentConfigurationId = useSelector(selectCurrentConfigurationId) || currentDeviceConfiguration?.id;

  const getStatusChanger = (configuration) => {
    const user = configuration?.deviceOwnership?.lastUpdatedBy;
    return user?.profile?.fullName || 'No user found';
  }

  const getStatusChangeDate = (configuration) => {
    return dateFormatted(configuration?.deviceOwnership?.updatedOn);
  }

  const historyMenuItems = currentHistory.map((deviceConfiguration) => {
    return {
      id: deviceConfiguration.id,
      name: getStatusChanger(deviceConfiguration),
      secondary: getStatusChangeDate(deviceConfiguration),
      icon: <DeviceIcon deviceConfiguration={deviceConfiguration} />,
      children: <DeviceTag configuration={deviceConfiguration} />,
    }
  })

  const onSelect = (id) => {
    dispatch(setCurrentConfigurationId(id === currentDeviceConfiguration?.id ? null : id));
    dispatch(setCurrentWirelessInterfaceId(null));
  }

  return (
    <MenuListWithImage
      items={historyMenuItems}
      selectedItemId={currentConfigurationId}
      selectItem={onSelect}
      size='big'
    />
  );
}