import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentConfiguration } from "../../../store/deviceConfigurationStore/deviceConfigurationSelectors";
import {
  selectCurrentDeviceCanEdit,
  selectCurrentDeviceId,
  selectCurrentDevicePermissions,
} from "../../../store/deviceStore/deviceSelectors";
import { checkDevicePermissions } from "../../../store/deviceStore/deviceRequests";
import { useTypedDispatch } from "../../../app/state";
import { resetDevicePermissions } from "../../../store/deviceStore/deviceState";

const CanEditDevice = ({ children, ...parentProps }) => {
  const dispatch = useTypedDispatch();
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const currentConfiguration = useSelector(selectCurrentConfiguration);
  const isHistorySelected = Boolean(currentConfiguration?.parentDeviceConfiguration);
  const canEdit = useSelector(selectCurrentDeviceCanEdit);
  const devicePermissionsObject = useSelector(selectCurrentDevicePermissions);
  const permissionDeviceId = devicePermissionsObject.currentDeviceId;

  useEffect(() => {
    if (currentDeviceId && currentDeviceId !== permissionDeviceId) {
      dispatch(resetDevicePermissions(currentDeviceId));
      if (currentDeviceId !== -1) {
        dispatch(checkDevicePermissions(currentDeviceId));
      }
    }
  }, [ currentDeviceId ])

  const formDisabled = !isHistorySelected && !canEdit;

  return (
    <>
      {
        React.Children.map(children, child => {
          const props = {
            ...child?.props,
            ...parentProps,
            formDisabled,
          };

          return child ? React.cloneElement(child, props) : null;
        })
      }
    </>
  )
}

export default CanEditDevice;