import qs from "query-string";

export function paramsSerializer(requestQuery: Record<string, any>) {
  for (const param in requestQuery) {
    if (Array.isArray(requestQuery[param])) {
      if (!requestQuery[param].length) {
        delete requestQuery[param];
      } else {
        requestQuery[`${param}[]`] = requestQuery[param].join(',');
        delete requestQuery[param];
      }
    } else {
      if (typeof requestQuery[param] === 'undefined') {
        delete requestQuery[param];
      }
    }
  }
  return qs.stringify(requestQuery, { encode: false });
}